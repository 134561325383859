<template>
  <NavBar />
  <header class="lg:px-16 px-5 bg-white flex flex-wrap items-center py-3">
    <div class="flex-1 flex justify-between items-center">
      <a href="#" class="text-3xl font-redHat text-[#F5CA40] font-bold">Live Instagram</a>
    </div>

    <div class="hidden md:flex md:items-center md:w-auto w-full" id="menu">
      <nav>
        <ul class="md:flex items-center justify-between text-base text-gray-700 pt-3 md:pt-0 gap-1">
          <li>
            <!-- <div class="relative p-0 rounded-[20px] w-[250px] max-w-lg"> -->
            <!-- <input type="text" class="rounded-[20px] p-3 w-full" name="search" placeholder="Cari"> -->
            <!-- </div> -->
            <!-- <button
              class="md:p-4 py-3 block flex items-center justify-center w-[200px] h-[40px] rounded-md bg-[#50C996] text-black py-2.5 font-semibold">
              Customer Baru
            </button> -->
          </li>
          <li>

            <!-- <button
              class="md:p-4 py-3 px-0 block flex items-center justify-center w-[100px] h-[40px] rounded-md bg-[#F5CA40] text-black px-6 py-2.5 font-semibold gap-2">
              Filter By <i class="bi bi-funnel-fill"></i>
            </button> -->
          </li>
        </ul>
      </nav>
    </div>
  </header>


  <div class="px-5 pt-5 mb-2">
    <datalist id="instagram">
      <option v-for="customer in dataCustomer" :key="customer.instagram" :value="customer.instagram" />
    </datalist>
    <!-- <div class="flex flex-col">
            <div class=" overflow-x-auto">
                <div class="p-1.5 min-w-full inline-block align-middle">
                    <div class="">
                        
                        
                        
                    </div>
                </div>
            </div>
        </div> -->


    <div class="">
      <!-- for datatable -->

      <table id="example" class="display z-50 w-full">
        <thead class="" style="
              position: sticky !important;
                top: 100px !important;
              background-color: white;
              z-index: 1;
            ">
          <tr>
            <th styles="width: 100px;">No.</th>
            <th>Instagram</th>
            <th>Kode Produk</th>
            <th>Varian</th>
            <th>Size</th>
            <th>Qty</th>
            <th>Harga</th>
            <th>Berat</th>
            <!-- <th>Harga Jual</th> -->
            <th>Total Berat</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr class="tr-table" v-for="(item, index) in records" :key="item.id"
            :class="{ 'bg-warning': item.info_update == false && item.id != undefined }"> <!-- :key="item.id" -->
            <td class="custom-td-left w-[50px]">{{ index + 1 }}
              <span class="tooltip2" :style="{ '--tooltip-content': `'Data belum disimpan'` }"
                v-if="item.info_update == false && item.id != undefined">
                <i class="bi bi-info-circle-fill text-red-600"></i>
              </span>
            </td>
            <!--<td><input type="text" v-model="item.ig" /></td> -->
            <td class="w-[250px]">
              <div class="m-2 flex">
                <input list="instagram" v-model="item.instagram" placeholder="Pilih Akun Instagram"
                  class="rounded h-[40px]  w-full border border-[#e8e8e8] no-arrows text-center"
                  @keydown.enter="focusNext" />
              </div>
            </td>
            <td class="w-[200px]">
              <div class="m-2">
                <!-- <multiselect v-model="item.id_product" :options="dataProduct" :custom-label="nameWithLangProduct"
                    placeholder="Pilih product" label="name" :track-by="'id'" :selectLabel="''" :deselectLabel="''"
                    class="" @update:modelValue="handleProductChange(index)"></multiselect> -->
                <input type="text" v-model="item.code_product"
                  class="rounded h-[40px] px-1  w-full border border-[#e8e8e8] no-arrows text-start"
                  @keydown.enter="focusNext" @focus="selectAll($event)">
              </div>
            </td>
            <td class="w-[150px]">
              <div class="m-2">
                <input type="text" v-model="item.color"
                  class="rounded h-[40px] px-1 w-full border border-[#e8e8e8] no-arrows text-start"
                  @keydown.enter="focusNext" @focus="selectAll($event)">
              </div>
            </td>
            <td class="w-[90px]">
              <div class="m-2">
                <input list="size" v-model="item.size" placeholder="Pilih Size"
                  class="rounded h-[40px]  w-full border border-[#e8e8e8] no-arrows text-center"
                  @keydown.enter="focusNext" @focus="selectAll($event)" />
                <datalist id="size">
                  <option v-for="customer in options" :key="customer.size" :value="customer.size" />
                </datalist>

              </div>
            </td>
            <td class="w-[60px] py-0">
              <div class="m-2">
                <input type="number" v-model="item.qty" @input="handleProductChange(index)" min="1"
                  class="rounded h-[40px]  w-full border border-[#e8e8e8] no-arrows text-center"
                  @keydown.enter="addRow(index, $event);" @focus="selectAll($event)" />
              </div>
            </td>
            <td class="w-[180px] py-0">
              <div class="m-2">
                <input type="text" v-model="item.price_2" @input="handleProductChange(index)"
                  class="rounded h-[40px] px-1  w-full border border-[#e8e8e8] no-arrows text-start"
                  @keydown.enter="focusNext" @focus="selectAll($event)" />
              </div>
            </td>
            <td class="w-[60px] py-0">
              <div class="m-2">
                <input type="number" v-model="item.weight" step="0.01" @input="handleProductChange(index)" min="0"
                  class="rounded h-[40px]  w-full border border-[#e8e8e8] no-arrows text-center"
                  @keydown.enter="focusNext" @focus="selectAll($event)" />
              </div>
            </td>

            <td class="w-[50px]">
              <div class="m-2">
                {{ item.total_weight }}
              </div>

            </td>
            <td class="custom-td-right w-[160px]">
              <button type="button"
                v-if="checkingLastRow(index) && item.instagram != '' && item.code_product != '' && item.color != '' && item.size != '' && item.price != 0 && item.weight != 0 && item.qty != 0"
                @click="addRow(index, $event)"
                class="inline-flex items-center gap-x-2 text-lg ms-4 font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                <i class="bi bi-arrow-return-left"></i>
                <!-- <i class="bi bi-plus-circle-fill"></i> -->
              </button>
              <button type="button" @click="addMoreDetail(index)"
                v-if="item.instagram != '' && item.code_product != '' && item.color != '' && item.size != '' && item.price != 0 && item.weight != 0 && item.qty != 0"
                class="inline-flex items-center gap-x-2 text-lg ms-4 font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                <i class="bi bi-plus-circle-fill"></i>
              </button>
              <button type="button" v-if="!checkingLastRow(index)" @click="editDetail(index)"
                class="inline-flex items-center gap-x-2 text-lg ms-4 font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                <i class="bi bi-floppy2-fill"></i>
              </button>
              <button type="button" v-if="!checkingLastRow(index)" @click="openModalDelete(index)"
                class="inline-flex items-center gap-x-2 text-lg font-semibold ms-5 text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                <i class="bi bi-trash"></i>
              </button>
              <!-- for button delete cadangan jika dibutuhkan-->
              <!-- <button type="button" :disabled="index == 0 && records.length == 1" @click="openModalDelete(index)"
                  class="inline-flex items-center gap-x-2 text-lg font-semibold ms-5 text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                  <i class="bi bi-trash"></i>
                </button> -->
              <!-- <button @click="addRow" class="mt-4 p-2 bg-blue-500 text-white rounded-md">Add New Row</button> -->

              <!-- <button type="button"  @click="openModalDelete(index)" class="inline-flex items-center gap-x-2 text-lg font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                          <i class="bi bi-plus-circle-fill"></i>
                                      </button> -->
            </td>
            <!-- <td>{{ item.position }}</td>
                                <td>{{ item.office }}</td>
                                <td>{{ item.age }}</td> -->
          </tr>
        </tbody>
      </table>

    </div>


  </div>



  <div class="w-full mt-4 p-4">

    <!-- fixed bottom-4 right-6 -->
    <!-- <button type="submit" v-if="records.length > 1"
      class="font-redHat text-base text-white h-[40px] w-[243px] bg-blue-500 rounded-md bg-red-600">

      Selesai Live
    </button> -->

    <!-- fixed bottom-4 right-6 flex gap-2 -->
    <div class=" flex gap-2 justify-end">
      <button type="button" @click="simpanBackToDashboard" tabindex="-1"
        class="bg-green-600 font-redHat text-base text-white h-[40px] w-[150px]  rounded-md">
        Simpan
      </button>
      <div @click="convertToExcelApi"
        class="font-redHat text-base  h-[40px] w-[150px] rounded-md text-green-600  border-2 border-green-600 me-2 cursor-pointer">
        <div class="flex justify-center items-center h-full gap-2">
          <img src="../../../assets/img/icon_excel.png" alt="">
          Export Excel
        </div>
      </div>
      <button @click="selesaiLive" tabindex="-1"
        class="font-redHat text-base text-white h-[40px] w-[150px]  rounded-md bg-red-600">
        Selesai Live
      </button>
    </div>
  </div>




  <!-- modal delete -->
  <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    style="z-index:2">
    <!-- Modal content -->
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full" @click.stop>
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">Delete Live IG Data</h2>
        <button @click="closeModal" class="text-red-600 hover:text-gray-900">
          ✕
        </button>
      </div>
      <p class="text-gray-700 mb-10">
        Are you sure wants to delete this data?
      </p>

      <div class="space-y-3">
        <div class="border-t px-2"></div>
        <div class="button-group flex justify-end">
          <button @click="closeModal" class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
            Close
          </button>
          <button @click="confirmDelete();" class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Multiselect from 'vue-multiselect'
import axios from "axios";
// import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, rupiah, goPage, AlertBottom, rupiahInput, rupiahoutput } from "@/assets/js/function";
import Cookies from "js-cookie";
import XLSX from "xlsx/dist/xlsx.full.min.js";

export default {
  components: {
    NavBar,
    Multiselect
  },

  data() {
    return {

      records: [{
        // {
        // ig: '',
        // kode_produk: '',
        // warna: '',
        // size: '',
        // qty: '',
        // harga_jual: '',
        // berat: '',
        // isAdded: false // New row starts with "Add Row" button
        // }
        // id: '1',
        id_customer: 0,
        instagram: '',
        // id_product: '',
        code_product: '',
        color: '',
        size: '',
        qty: 0,
        price: 0,
        price_2: 0,
        weight: 0,
        selling_price: 0,
        total_weight: 0,
        showSelectOptionCustomer: true,
        info_update: false,
      }],

      recordDelete: [{
        id_customer: 0,
        instagram: '',
        code_product: '',
        color: '',
        size: '',
        qty: 0,
        price: 0,
        price_2: 0,
        weight: 0,
        selling_price: 0,
        total_weight: 0,
        showSelectOptionCustomer: true,
        info_update: false,
      }],


      options: [
        { size: 'XS' },
        { size: 'S' },
        { size: 'M' },
        { size: 'L' },
        { size: 'XL' },
        { size: '2XL' },
        { size: '3XL' },
        { size: 'FS' },
      ],
      showMultiselect: false,
      // currentData: [],

      // value: {name: '@IgUsername'},
      dataCustomer: [],
      dataProduct: [],
      dataColor: [],
      dataSize: [],

      isModalOpen: false,
      currentDeleteIndex: null,

      status_update: false,

      tempObject: [],

    }
  },

  watch: {

    records: {
      deep: true,
      handler(newval) {
        let oldval = JSON.parse(localStorage.getItem('records')) || [];
        // Save records to localStorage
        if (this.status_update == false && oldval.length > 1) {
          newval.forEach((item, index) => {
            // Compare objects deeply
            if (JSON.stringify(item) !== JSON.stringify(oldval[index])) {
              if (!(this.tempObject.some(item_data => JSON.stringify(item_data) === JSON.stringify(oldval[index]))) && this.tempObject.findIndex(item_data => item_data.id === oldval[index].id) == -1) {
                this.tempObject.push(oldval[index]);
              }
              item.info_update = false;
            }
            //1. temp object udh bener 
            //kalo ada di temp object true 
            if (this.tempObject.some(item_data =>
              item_data.id_customer === item.id_customer &&
              item_data.instagram === item.instagram &&
              item_data.code_product === item.code_product &&
              item_data.color === item.color &&
              item_data.size === item.size &&
              item_data.qty === item.qty &&
              item_data.price === item.price &&
              item_data.price_2 === item.price_2 &&
              item_data.weight === item.weight &&
              item_data.selling_price === item.selling_price &&
              item_data.total_weight === item.total_weight &&
              item_data.showSelectOptionCustomer === item.showSelectOptionCustomer &&
              item_data.isAdded === item.isAdded &&
              item_data.id === item.id
            )) {
              let index = this.tempObject.findIndex(item_data => item_data.id === item.id);
              this.tempObject.splice(index, 1);
              item.info_update = true;
              this.status_update = true;
            }

            // if (item.instagram == '@storeid') {
            //   console.log(item.color);
            //   console.log(oldval[index].color);
            //   console.log(this.tempObject);
            // }
          });
        }

        localStorage.setItem('records', JSON.stringify(newval));
        this.status_update = false;
      },

    },




  },




  created() {
    const token = Cookies.get("token");
    // console.log(token)
    this.config = {
      headers: { Authorization: `Bearer ` + token },
      timeout: 100000,
    }
    this.index();
    // Load from localStorage when the component is created
    const savedLiveIg = localStorage.getItem('records');
    if (savedLiveIg) {
      this.records = JSON.parse(savedLiveIg);
    } else {
      console.log('No records found in localStorage.');
    }
  },

  methods: {
    rupiah,

    resetTempValidation() {

      this.status_update = true;
    },

    addNumberToObjects(objArray) {
      objArray = objArray.filter(item => item.code_product !== '');

      for (let i = 0; i < objArray.length; i++) {
        const { code_product, color, price_2, weight, ...rest } = objArray[i];  // Extract fields to be renamed

        const newObj = {
          No: i + 1,
          "Kode Produk": code_product,  // Rename id_customer to customer_id
          "Varian": color,
          "Harga": price_2,
          "Berat": weight,
          ...rest
        };
        objArray[i] = newObj;

      }
      return objArray;
    },
    setSameProductPrice(index) {
      let tempCodeProduct = this.records[index].code_product;
      let tempPrice = this.records[index].price;
      let tempWeight = this.records[index].weight;

      this.records.forEach((item, index) => {
        if (item.code_product == tempCodeProduct) {
          item.price = tempPrice;
          item.price_2 = rupiahInput(tempPrice);
          item.weight = tempWeight;
        }
      });
      // Delay of 2000 milliseconds (2 seconds)

    },
    convertToExcel() {

      const length = this.records.length;

      if (this.records[length - 1].instagram != '') {
        this.addLastRow(length - 1);
      } else {
        ShowLoading();

        // Create a deep copy of `this.records` to avoid modifying the original data



        const data = JSON.parse(JSON.stringify(this.records));


        const ArrExport = this.addNumberToObjects(data);

        for (let i = 0; i < ArrExport.length; i++) {

          ArrExport[i]["code_product"] = ArrExport[i]["Kode Produk"];

          delete ArrExport[i]["id"];
          delete ArrExport[i]["showSelectOptionCustomer"];
          delete ArrExport[i]["selling_price"];
          delete ArrExport[i]["price"];
          delete ArrExport[i]["total_weight"];
          delete ArrExport[i]["code_product"];
          delete ArrExport[i]["id_customer"];
          delete ArrExport[i]["isAdded"];
          delete ArrExport[i]["id_customer"];
          delete ArrExport[i]["price"];
          delete ArrExport[i]["selling_price"];
          delete ArrExport[i]["total_weight"];
          delete ArrExport[i]["showSelectOptionCustomer"];
          delete ArrExport[i]["isAdded"];



        }

        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Create a new worksheet
        const ws = XLSX.utils.json_to_sheet(ArrExport);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'LAPORAN LIVE IG');
        // Convert the workbook to an XLSX file and download it
        XLSX.writeFile(wb, 'LAPORAN IG' + ".xlsx");
        CloseLoading();
      }

    },
    addNumberToObjectsApi(objArray) {
      objArray = objArray.filter(item => item.code_product !== '');

      for (let i = 0; i < objArray.length; i++) {
        const { code, color, normal_price, weight, instagram, size, qty, total_weight } = objArray[i];  // Extract fields to be renamed

        const newObj = {
          No: i + 1,
          "Instagram": instagram,
          "Kode Produk": code,  // Rename id_customer to customer_id
          "Varian": color,
          "Size": size,
          "Qty": qty,
          "Harga": normal_price,
          "Berat": weight,
          "Total Berat": total_weight,
        };
        objArray[i] = newObj;

      }
      return objArray;
    },
    convertToExcelApi() {
      const index = this.records.findIndex(record => record.info_update === false);

      if (index > -1 && this.records[index].id != undefined) {
        AlertPopup("error", "", 'Terdapat data yang belum tersimpan, Silahkan cek baris no ' + (index + 1), 2000, false);
        return;
      }

      const length = this.records.length;

      if (this.records[length - 1].instagram != '') {
        this.addLastRow(length - 1);
      } else {
        ShowLoading();
        let formData = new FormData();
        const idString = this.records
          .filter(item => item.id !== undefined) // Ensure 'id' exists
          .map(item => item.id) // Map to 'id'
          .join(',');


        formData.append('id', idString);

        this.api = base_url + 'live_ig_export';

        axios
          .post(this.api, formData, this.config)
          .then((response) => {
            if (response.data.status === true) {
              const data = response.data.data;
              const ArrExport = this.addNumberToObjectsApi(data);
              for (let i = 0; i < ArrExport.length; i++) {

                ArrExport[i]["code_product"] = ArrExport[i]["Kode Produk"];
                delete ArrExport[i]["id"];
                delete ArrExport[i]["showSelectOptionCustomer"];
                delete ArrExport[i]["selling_price"];
                delete ArrExport[i]["price"];
                delete ArrExport[i]["total_weight"];
                delete ArrExport[i]["code_product"];
                delete ArrExport[i]["id_customer"];
                delete ArrExport[i]["isAdded"];
                delete ArrExport[i]["id_customer"];
                delete ArrExport[i]["price"];
                delete ArrExport[i]["selling_price"];
                delete ArrExport[i]["total_weight"];
                delete ArrExport[i]["showSelectOptionCustomer"];
                delete ArrExport[i]["isAdded"];

              }

              // Create a new workbook
              const wb = XLSX.utils.book_new();
              // Create a new worksheet
              const ws = XLSX.utils.json_to_sheet(ArrExport);
              // Add the worksheet to the workbook
              XLSX.utils.book_append_sheet(wb, ws, 'LAPORAN LIVE IG');
              // Convert the workbook to an XLSX file and download it
              XLSX.writeFile(wb, 'LAPORAN IG' + ".xlsx");
              CloseLoading();

            } else {
              CloseLoading();
              AlertPopup("error", "", response.data.message, 1500, false);
            }
          })
          .catch((error) => {
            CloseLoading();
            ErrorConnectionTimeOut(error);
          });

      }
    },


    focusNext(event) {
      const inputs = Array.from(document.querySelectorAll('input')); // Get all input elements
      const currentIndex = inputs.indexOf(event.target); // Find the current input's index

      if (currentIndex !== -1 && inputs[currentIndex + 1]) {
        // Focus on the next input if it exists
        inputs[currentIndex + 1].focus();
      }
    },

    nameWithLangCustomer({ instagram }) {
      return `${instagram}`
    },
    nameWithLangProduct({ code }) {
      return `${code}`
    },

    nameWithLangSize({ size }) {
      return `${size}`
    },

    simpanBackToDashboard() {
      const index = this.records.findIndex(record => record.info_update === false);

      if (index > -1 && this.records[index].id != undefined) {
        AlertPopup("error", "", 'Terdapat data yang belum tersimpan, Silahkan cek baris no ' + (index + 1), 2000, false);
        return;
      }
      const length = this.records.length;
      if (this.records[length - 1].instagram != '') {
        this.addLastRow(length - 1);
      } else {
        goPage('dashboard');
      }


    },

    selectAll(event) {
      // Get the currently focused element
      // event.target.select();

      const input = event.target;
      input.select();
    },

    handleProductChange(index) {

      this.records[index].price_2 = rupiahInput(this.records[index].price_2);
      this.records[index].price = rupiahoutput(this.records[index].price_2);

      this.records[index].selling_price = this.records[index].price * this.records[index].qty;
      this.records[index].total_weight = this.records[index].weight * this.records[index].qty;

    },
    handleCustomerSelectOption(index) {
      const val = this.records[index].showSelectOptionCustomer;
      if (val == true) {
        this.records[index].showSelectOptionCustomer = false;
        this.records[index].id_customer = 0;
        this.records[index].instagram = '';
      } else {
        this.records[index].showSelectOptionCustomer = true;
        this.records[index].instagram = '';
        this.records[index].id_customer = '';
      }

    },
    // handleSizeChange(index) {

    //   this.dataSize = []
    //   const index2 = this.dataProduct.findIndex((record) => record.id === this.records[index].id_product.id);
    //   const index3 = this.dataProduct[index2].product_detail.findIndex((record) => record.color === this.records[index].color);
    //   this.dataProduct[index2].product_detail[index3].sizeqty.forEach((item) => {
    //     this.dataSize.push(item.size);
    //   });
    // },
    editDetail(index) {
      const errors = [
        { field: "qty", message: "Qty tidak boleh kosong", invalid: (val) => val == 0 },
        { field: "instagram", message: "Instagram tidak boleh kosong", invalid: (val) => val === "" },
        { field: "code_product", message: "Kode Produk tidak boleh kosong", invalid: (val) => val === "" },
        { field: "color", message: "Varian tidak boleh kosong", invalid: (val) => val === "" },
        { field: "size", message: "Size tidak boleh kosong", invalid: (val) => val === "" },
        { field: "price", message: "Harga tidak boleh kosong", invalid: (val) => val == 0 },
        { field: "weight", message: "Berat tidak boleh kosong", invalid: (val) => val == 0 },
      ];

      for (const { field, message, invalid } of errors) {
        if (invalid(this.records[index][field])) {
          AlertPopup("error", "", message, 2000, false);
          return true;
        }
      }

      ShowLoading();
      let formData = new FormData();

      // Retrieve the current record
      const currentItem = this.records[index];

      // Append relevant fields to formData 
      formData.append('id_preorder', localStorage.getItem('id_preorder')); // Add any additional necessary fields
      formData.append('counter', localStorage.getItem('counter'));
      formData.append('id_supplier', localStorage.getItem('id_supplier'));
      formData.append('type', 'LIVE_IG');

      formData.append('instagram', currentItem.instagram || '');
      formData.append('code_product', currentItem.code_product || '');
      formData.append('color', currentItem.color || '');
      formData.append('size', currentItem.size || '');
      formData.append('qty', currentItem.qty || 0);
      formData.append('price', currentItem.price || 0);
      formData.append('weight', currentItem.weight || 0);
      formData.append('total_weight', currentItem.total_weight || 0);
      formData.append('date', localStorage.getItem('live_ig_date'));

      if (this.records[index].id == undefined || this.records[index].id == '') {
        this.api = base_url + 'live_ig_insert';
      } else {
        formData.append('id', this.records[index].id);
        this.api = base_url + 'live_ig_update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status === true) {
            if (this.records[index].id == undefined || this.records[index].id == '') {
              this.records[index].id = response.data.data.id;
            }
            this.records[index].info_update = true;
            this.resetTempValidation();
            AlertBottom(response.data.message);
            this.setSameProductPrice(index);
            CloseLoading();
            location.reload();

          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });
    },
    addMoreDetail(index) {
      if (this.records[index].qty == 0) {
        AlertPopup("error", "", "Qty tidak boleh kosong", 1500, false);
        return true;
      }

      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'));
      formData.append('counter', localStorage.getItem('counter'));
      formData.append('id_supplier', localStorage.getItem('id_supplier'));
      formData.append('type', 'LIVE_IG');

      // Append only the last filled record to the formData
      formData.append('instagram', this.records[index].instagram || '');
      formData.append('code_product', this.records[index].code_product || '');
      formData.append('color', this.records[index].color || '');
      formData.append('size', this.records[index].size || '');
      formData.append('qty', this.records[index].qty || 0);
      formData.append('price', this.records[index].price || 0);
      formData.append('weight', this.records[index].weight || 0);
      formData.append('total_weight', this.records[index].total_weight || 0);

      if (this.records[index].id == undefined || this.records[index].id == 0) {
        this.api = base_url + 'live_ig_insert';
      } else {
        formData.append('id', this.records[index].id);
        this.api = base_url + 'live_ig_update';
      }

      // if(this.records[index].price_2){

      // }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status === true) {

            const id = response.data.data.id;

            this.resetTempValidation();
            this.records[index].id = id;
            this.records[index].info_update = true;


            AlertBottom(response.data.message);
            // Add a new row with a unique id
            this.records.splice(index + 1, 0, {
              id_customer: 0,
              instagram: '',
              code_product: this.records[index].code_product,
              color: this.records[index].color,
              size: '',
              qty: 0,
              price: this.records[index].price,
              price_2: rupiahInput(this.records[index].price),
              weight: this.records[index].weight,
              selling_price: 0,
              total_weight: 0,
              showSelectOptionCustomer: true,
              isAdded: false, // Initialize the flag
              info_update: true,
            });
            this.setSameProductPrice(index);
            CloseLoading();
          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });
    },
    addRow(index, event) {
      if (this.records[index].qty == 0) {
        AlertPopup("error", "", "Qty tidak boleh kosong", 1500, false);
        return true;
      }

      if (this.checkingLastRow(index) == false) {

        // Get all input elements
        const inputs = Array.from(document.querySelectorAll('input:not([aria-hidden="true"]):not([inert])'));
        const currentIndex = inputs.indexOf(event.target);

        if (currentIndex !== -1 && inputs[currentIndex + 1]) {
          inputs[currentIndex + 1].focus();
        }

        return true;
      }

      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'));
      formData.append('counter', localStorage.getItem('counter'));
      formData.append('id_supplier', localStorage.getItem('id_supplier'));

      formData.append('type', 'LIVE_IG');
      // Append only the last filled record to the formData
      formData.append('instagram', this.records[index].instagram || '');
      formData.append('code_product', this.records[index].code_product || '');
      formData.append('color', this.records[index].color || '');
      formData.append('size', this.records[index].size || '');
      formData.append('qty', this.records[index].qty || 0);
      formData.append('price', this.records[index].price || 0);
      formData.append('weight', this.records[index].weight || 0);
      formData.append('total_weight', this.records[index].total_weight || 0);
      formData.append('date', localStorage.getItem('live_ig_date'));

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'live_ig_insert';
      } else {
        this.api = base_url + 'live_ig_insert';
      }


      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status === true) {
            const id = response.data.data.id;

            const indexInsta = this.dataCustomer.findIndex((record) => record.name == this.records[index].instagram);
            if (indexInsta === -1) {
              this.dataCustomer.push({
                instagram: this.records[index].instagram,
              })
            }

            // Update the corresponding row in this.records to reflect that it has been added

            this.records[index].id = id;

            this.records[index].info_update = true;
            this.resetTempValidation();
            this.records.push({
              id_customer: 0,
              instagram: '',
              code_product: '',
              color: '',
              size: '',
              qty: 0,
              price: 0,
              price_2: 0,
              weight: 0,
              selling_price: 0,
              total_weight: 0,
              showSelectOptionCustomer: true,
              isAdded: false, // Initialize the flag
              info_update: false,
            })
            CloseLoading();
            AlertBottom(response.data.message);
            this.setSameProductPrice(index);
            this.$nextTick(() => {
              setTimeout(() => {
                // Get all input elements
                const inputs = Array.from(document.querySelectorAll('input:not([aria-hidden="true"]):not([inert])'));
                const currentIndex = inputs.indexOf(event.target);


                if (currentIndex !== -1 && inputs[currentIndex + 1 - 7]) {
                  inputs[currentIndex + 1 - 7].focus();
                }
              }, 500); // Wait for 2 seconds
            });



          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });


    },
    addLastRow(index) {
      if (this.records[index].qty == 0) {
        AlertPopup("error", "", "Qty tidak boleh kosong", 1500, false);
        return true;
      }

      if (this.checkingLastRow(index) == false) {
        // Get all input elements
        return true;
      }

      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'));
      formData.append('counter', localStorage.getItem('counter'));
      formData.append('id_supplier', localStorage.getItem('id_supplier'));

      formData.append('type', 'LIVE_IG');
      // Append only the last filled record to the formData
      formData.append('instagram', this.records[index].instagram || '');
      formData.append('code_product', this.records[index].code_product || '');
      formData.append('color', this.records[index].color || '');
      formData.append('size', this.records[index].size || '');
      formData.append('qty', this.records[index].qty || 0);
      formData.append('price', this.records[index].price || 0);
      formData.append('weight', this.records[index].weight || 0);
      formData.append('total_weight', this.records[index].total_weight || 0);
      formData.append('date', localStorage.getItem('live_ig_date'));

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'live_ig_insert';
      } else {
        this.api = base_url + 'live_ig_insert';
      }


      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status === true) {
            const id = response.data.data.id;

            const indexInsta = this.dataCustomer.findIndex((record) => record.name == this.records[index].instagram);
            if (indexInsta === -1) {
              this.dataCustomer.push({
                instagram: this.records[index].instagram,
              })
            }
            this.resetTempValidation();
            // Update the corresponding row in this.records to reflect that it has been added
            this.records[index].id = id;
            this.records[index].info_update = true;

            this.records.push({
              id_customer: 0,
              instagram: '',
              code_product: '',
              color: '',
              size: '',
              qty: 0,
              price: 0,
              price_2: 0,
              weight: 0,
              selling_price: 0,
              total_weight: 0,
              showSelectOptionCustomer: true,
              isAdded: false, // Initialize the flag
              info_update: false,
            })
            CloseLoading();
            AlertBottom(response.data.message);

          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });


    },
    checkingLastRow(index) {

      if (index == this.records.length - 1) {
        // this.records[index].isAdded = true;
        return true;

        // this.records.splice(index + 1, 0, );
      } else {
        // this.records[index].isAdded = false;
        return false;

      }

      // return index === this.records.length - 1;
    },
    index() {
      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'))
      formData.append('id_supplier', localStorage.getItem('id_supplier'))



      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'live_ig';
      } else {
        this.api = base_url + 'live_ig';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status === true) {
            // this.currentData = response.data.data

            this.dataCustomer = response.data.customer; //to fetch customer

            // this.dataProduct = response.data.product; //to fetch product
            // this.dataColor = response.data.data; //to fetch color
            //this.dataSize = response.data.data; //to fetch size
            // console.log(response.data.data)
            // this.currentId = response.data.id;



            // save to local storage
            // localStorage.setItem('data_supplier', JSON.stringify(response.data.data_supplier));
            localStorage.getItem('id_preorder')
            // localStorage.setItem('data_product_detail', JSON.stringify(response.data.product_detail));



            // if (response.data.status == true) {
            //     this.data = [];

            //     response.data.data.forEach((item, index) => {
            //         let item_prepare = { ...item, no: (index + 1), };
            //         this.data.push(item_prepare);
            //     });

            //     CloseLoading();
            // }


            CloseLoading();
          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });
    },
    selesaiLive() {

      const index = this.records.findIndex(record => record.info_update === false);

      if (index > -1 && this.records[index].id != undefined) {
        AlertPopup("error", "", 'Terdapat data yang belum tersimpan, Silahkan cek baris no ' + (index + 1), 2000, false);
        return;
      }
      ShowLoading();
      const length = this.records.length;

      if (this.records[length - 1].instagram != '') {
        this.addLastRow(length - 1);
      } else {
        localStorage.removeItem('records');
        goPage('dashboard');
      }


    },
    openModalDelete(index) {
      this.currentDeleteIndex = index;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    confirmDelete() {
      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'));
      formData.append('counter', localStorage.getItem('counter'));
      formData.append('type', "LIVE_IG");
      formData.append('id', this.records[this.currentDeleteIndex].id);

      if (this.records[this.currentDeleteIndex].id == 0 || this.records[this.currentDeleteIndex].id == undefined) {
        this.records.splice(this.currentDeleteIndex, 1);
        CloseLoading();
        this.closeModal();
        return true;
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'live_ig_delete';
      } else {
        this.api = base_url + 'live_ig_delete';
      }


      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {
            this.records.splice(this.currentDeleteIndex, 1);

            localStorage.removeItem('id_row');

            CloseLoading();
            this.closeModal(); // Close modal after deletion
            AlertBottom(response.data.message);

          } else {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });
    },

  }

}
</script>

<style scoped>
/* Adjust positioning and z-index for the multiselect dropdown */
.multiselect__content {
  position: absolute;
  z-index: 9999;
  top: 100%;
}

.multiselect-full-width .multiselect__select {
  width: 100%;
}

.no-arrows {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: none;
  /* Remove default appearance */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* Chrome, Safari, Edge */
  margin: 0;
  /* Remove margin */
}
</style>