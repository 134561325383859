import { createRouter, createWebHistory } from 'vue-router'
import Cookies from "js-cookie";
// import HomeView from '../views/HomeView.vue'
// new pages
import SignIn from '@/views/pages/auth/SignIn.vue'

//dashboard
import DashBoard from '@/views/pages/DashBoard.vue'

//category
import KategoriProduk from '@/views/pages/kategoriproduct/KategoriProduct.vue'
import AddKategoriProduk from '@/views/pages/kategoriproduct/AddKategoriProduk.vue'
import EditKategoriProduk from '@/views/pages/kategoriproduct/EditKategoriProduk.vue'

//product
import ProdukData from '@/views/pages/produk/ProdukData.vue'
import AddProdukData from '@/views/pages/produk/AddProdukData.vue'
import EditProdukData from '@/views/pages/produk/EditProdukData.vue'

//discount
import Discount from '@/views/pages/discount/Discount.vue'
import AddDiscount from '@/views/pages/discount/AddDiscount.vue'
import EditDiscount from '@/views/pages/discount/EditDiscount.vue'

//admin
import AdminMd from '@/views/pages/admin/AdminMd.vue'
import AddAdminMd from '@/views/pages/admin/AddAdminMd.vue'
import EditAdminMd from '@/views/pages/admin/EditAdminMd.vue'

//location
import LokasiPo from '@/views/pages/lokasi/LokasiPo.vue'
import AddLokasi from '@/views/pages/lokasi/AddLokasi.vue'
import EditLokasi from '@/views/pages/lokasi/EditLokasi.vue'

//supplier
import SupplierMasterData from '@/views/pages/supplier/SupplierMasterdata.vue'
import AddSupplierMasterData from '@/views/pages/supplier/AddSupplierMasterdata.vue'
import EditSupplierMasterData from '@/views/pages/supplier/EditSupplierMasterdata.vue'

// import JenisPenjualan from '@/views/pages/jenis-penjualan/JenisPenjualan.vue'
// import AddJenisPenjualan from '@/views/pages/jenis-penjualan/AddJenisPenjualan.vue'
// import EditJenisPenjualan from '@/views/pages/jenis-penjualan/EditJenisPenjualan.vue'
// import StatusPesanan from '@/views/pages/status-pesanan/StatusPesanan.vue'
// import AddStatuspesanan from '@/views/pages/status-pesanan/AddStatuspesanan.vue'
// import EditStatuspesanan from '@/views/pages/status-pesanan/EditStatuspesanan.vue'

//customer
import CustomerView from '@/views/pages/customer/CustomerView.vue'
import NewUpdateListOrder from '@/views/pages/listorder/UpdateListOrder.vue';
import NewAddCustomer from '@/views/pages/customer/NewAddCustomer.vue';

//preorder
import PoView from '@/views/pages/po/PoView.vue'
import AddPo from '@/views/pages/po/AddPo.vue'
import EditPo from '@/views/pages/po/EditPo.vue'

//list oder
import ListOrder from '@/views/pages/listorder/ListOrder.vue'
import AddListOrder from '@/views/pages/listorder/AddListOrder.vue'
import UpdateListOrder from '@/views/pages/listorder/UpdateListOrder.vue'

//live ig 
import LiveIg from '@/views/pages/liveig/LiveIg.vue'
import PoBatch from '@/views/pages/pobatch/PoBatch.vue'
import CreateLiveIg from '@/views/pages/liveig/CreateLiveIg.vue'
import DataLiveIg from '@/views/pages/liveig/DataLiveIg.vue'
import CreatePoBatch from '@/views/pages/pobatch/CreatePoBatch.vue'
import RekapDataIg from '@/views/pages/rekapdataliveig/RekapDataIg.vue'
import RekapLivepo from '@/views/pages/rekaplistorderan/RekapLivepo.vue'
import EditVerifikasiLiveIg from '@/views/pages/liveig/EditVerifikasiLiveig.vue'
import EditRekapDataIg from '@/views/pages/rekapdataliveig/EditRekapDataIg.vue'
import EditRekapListpo from '@/views/pages/rekaplistorderan/EditRekapListpo.vue'
import ForgotPassword from '@/views/pages/auth/ForgotPassword.vue'
import ConfirmOtp from '@/views/pages/auth/ConfirmOtp.vue'
import ChangePassword from '@/views/pages/auth/ChangePassword.vue'
import AddPobatchLocation from '@/views/pages/pobatch/AddPobatchLocation.vue'
import Dailyreport from '@/views/pages/report/dailyreport.vue'
import DepositReport from '@/views/pages/report/depositreport.vue'
import NewLiveShop from '@/views/pages/liveig/NewLiveShop.vue'
import Summaryreport from '@/views/pages/report/summaryreport.vue';
import NewEditcustomer from '@/views/pages/customer/NewEditcustomer.vue';

import NewViewInvoice from '@/views/pages/report/NewViewInvoice.vue';
import Accept from '@/views/pages/report/accept.vue';
import Summaryboughtreport from '@/views/pages/report/summaryboughtreport.vue';
import Listorderreport from '@/views/pages/report/listorderreport.vue';
import Auth from '@/views/pages/admin/AuthMd.vue';
import DailyreportView from '@/views/pages/report/dailyreportView.vue';
import Packaginglistreport from '@/views/pages/report/packaginglistreport.vue';
import Customersoldoutreport from '@/views/pages/report/customersoldoutreport.vue';
import Productsalesreport from '@/views/pages/report/productsalesreport.vue';
import BebanOperasional from '@/views/pages/bebanoperasional/BebanOperasional.vue';
import AddBebanOperasional from '@/views/pages/bebanoperasional/AddBebanOperasional.vue';
import EditBebanOperasional from '@/views/pages/bebanoperasional/EditBebanOperasional.vue';
import LabaRugi from '@/views/pages/labarugi/LabaRugi.vue';

const router = createRouter({
  history: createWebHistory("/"),

  linkActiveClass: "active",

  routes: [
    {
      path: '/',
      name: 'signin',
      component: SignIn,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/forgotpassword',
      name: 'forgotpassword',
      component: ForgotPassword,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/confirmotp',
      name: 'confirmotp',
      component: ConfirmOtp,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/changepassword',
      name: 'changepassword',
      component: ChangePassword,
      meta: {
        requiresAuth: true
      }
    },
    //dashboard
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashBoard,
      meta: { requiresAuth: true }
    },
    //category
    {
      path: '/kategoriproduk',
      name: 'kategoriproduk',
      component: KategoriProduk,
      meta: { requiresAuth: true }
    },
    {
      path: '/addkategoriproduk',
      name: 'addkategoriproduk',
      component: AddKategoriProduk,
      meta: { requiresAuth: true }
    },
    {
      path: '/editkategoriproduk',
      name: 'editkategoriproduk',
      component: EditKategoriProduk,
      meta: { requiresAuth: true }
    },
    //product
    {
      path: '/produk',
      name: 'produk',
      component: ProdukData,
      meta: { requiresAuth: true }
    },
    {
      path: '/addproduk',
      name: 'addproduk',
      component: AddProdukData,
      meta: { requiresAuth: true }
    },
    {
      path: '/editproduk',
      name: 'editproduk',
      component: EditProdukData,
      meta: { requiresAuth: true }
    },

    //admin
    {
      path: '/admin',
      name: 'admin',
      component: AdminMd,
      meta: { requiresAuth: true }
    },
    {
      path: '/addadmin',
      name: 'addadmin',
      component: AddAdminMd,
      meta: { requiresAuth: true }
    },
    {
      path: '/editadmin',
      name: 'editadmin',
      component: EditAdminMd,
      meta: { requiresAuth: true }
    },
    //location
    {
      path: '/lokasipo',
      name: 'lokasipo',
      component: LokasiPo,
      meta: { requiresAuth: true }
    },
    {
      path: '/addlokasi',
      name: 'addlokasi',
      component: AddLokasi,
      meta: { requiresAuth: true }
    },
    {
      path: '/Editlokasipo',
      name: 'Editlokasipo',
      component: EditLokasi,
      meta: { requiresAuth: true }
    },
    //supplier
    {
      path: '/supplier',
      name: 'supplier',
      component: SupplierMasterData,
      meta: { requiresAuth: true }
    },
    {
      path: '/addsupplier',
      name: 'addsupplier',
      component: AddSupplierMasterData,
      meta: { requiresAuth: true }
    },
    {
      path: '/editsupplier',
      name: 'editsupplier',
      component: EditSupplierMasterData,
      meta: { requiresAuth: true }
    },

    // finance
    {
      path: '/bebanoperasional',
      name: 'bebanoperasional',
      component: BebanOperasional,
      meta: { requiresAuth: true }
    },
    {
      path: '/addbebanoperasional',
      name: 'addbebanoperasional',
      component: AddBebanOperasional,
      meta: { requiresAuth: true }
    },
    {
      path: '/editbebanoperasional',
      name: 'editbebanoperasional',
      component: EditBebanOperasional,
      meta: { requiresAuth: true }
    },

    {
      path: '/labarugi',
      name: 'labarugi',
      component: LabaRugi,
      meta: { requiresAuth: true }
    },
    // {
    //   path: '/jenispenjualan',
    //   name: 'jenispenjualan',
    //   component: JenisPenjualan,
    //   meta: { requiresAuth: true }
    // },
    // {
    //   path: '/addjenispenjualan',
    //   name: 'addjenispenjualan',
    //   component: AddJenisPenjualan,
    //   meta: { requiresAuth: true }
    // },
    // {
    //   path: '/Editjenispenjualan',
    //   name: 'Editjenispenjualan',
    //   component: EditJenisPenjualan,
    //   meta: { requiresAuth: true }
    // },
    // {
    //   path: '/statuspesanan',
    //   name: 'statuspesanan',
    //   component: StatusPesanan,
    //   meta: { requiresAuth: true }
    // },
    // {
    //   path: '/addstatuspesanan',
    //   name: 'addstatuspesanan',
    //   component: AddStatuspesanan,
    //   meta: { requiresAuth: true }
    // },
    // {
    //   path: '/editstatuspesanan',
    //   name: 'editstatuspesanan',
    //   component: EditStatuspesanan,
    //   meta: { requiresAuth: true }
    // },

    //customer
    {
      path: '/customer',
      name: 'customer',
      component: CustomerView,
      meta: { requiresAuth: true }
    },
    {
      path: '/addcustomer',
      name: 'addcustomer',
      component: NewAddCustomer,
      meta: { requiresAuth: true }
    },
    {
      path: '/editcustomer',
      name: 'editcustomer',
      component: NewEditcustomer,
      meta: { requiresAuth: true }
    },
    //preorder
    {
      path: '/po',
      name: 'po',
      component: PoView,
      meta: { requiresAuth: true }
    },
    {
      path: '/addpo',
      name: 'addpo',
      component: AddPo,
      meta: { requiresAuth: true }
    },
    {
      path: '/editpo',
      name: 'editpo',
      component: EditPo,
      meta: { requiresAuth: true }
    },
    //list order
    {
      path: '/listorder',
      name: 'listorder',
      component: ListOrder,
      meta: { requiresAuth: true }
    },
    {
      path: '/editlistorder',
      name: 'editlistorder',
      component: UpdateListOrder,
      meta: { requiresAuth: true }
    },
    {
      path: '/addlistorder',
      name: 'addlistorder',
      component: AddListOrder,
      meta: { requiresAuth: true }
    },
    //add list
    //live ig
    {
      path: '/liveig',
      name: 'liveig',
      component: LiveIg,
      meta: { requiresAuth: true }
    },
    {
      path: '/createliveig',
      name: 'createliveig',
      component: CreateLiveIg,
      meta: { requiresAuth: true }
    },
    {
      path: '/dataliveig',
      name: 'dataliveig',
      component: DataLiveIg,
      meta: { requiresAuth: true }
    },
    {
      path: '/dailyreport',
      name: 'dailyreport',
      component: Dailyreport,
      meta: { requiresAuth: true }
    },
    {
      path: '/dailyreportview',
      name: 'dailyreportview',
      component: DailyreportView,
      meta: { requiresAuth: true }
    },
    {
      path: '/depositreport',
      name: 'depositreport',
      component: DepositReport,
      meta: { requiresAuth: true }
    },
    {
      path: '/customersoldoutreport',
      name: 'customersoldoutreport',
      component: Customersoldoutreport,
      meta: { requiresAuth: true }
    },
    {
      path: '/productsalesreport',
      name: 'productsalesreport',
      component: Productsalesreport,
      meta: { requiresAuth: true }
    },
    {
      path: '/pobatch',
      name: 'pobatch',
      component: PoBatch,
      meta: { requiresAuth: true }
    },
    {
      path: '/createpobatch',
      name: 'createpobatch',
      component: CreatePoBatch,
      meta: { requiresAuth: true }
    },
    {
      path: '/addbatchlocation',
      name: 'addbatchlocation',
      component: AddPobatchLocation,
      meta: { requiresAuth: true }
    },
    {
      path: '/updatelistorder',
      name: 'updatelistorder',
      component: NewUpdateListOrder,
      meta: { requiresAuth: true }
    },
    {
      path: '/rekapliveig',
      name: 'rekapliveig',
      component: RekapDataIg,
      meta: { requiresAuth: true }
    },
    {
      path: '/addliveshop',
      name: 'addliveshop',
      component: NewLiveShop,
      meta: { requiresAuth: true }
    },
    {
      path: '/editrekapliveig',
      name: 'editrekapliveig',
      component: EditRekapDataIg,
      meta: { requiresAuth: true }
    },
    {
      path: '/rekaplistpo',
      name: 'rekaplistpo',
      component: RekapLivepo,
      meta: { requiresAuth: true }
    },
    {
      path: '/editrekaplistpo',
      name: 'editrekaplistpo',
      component: EditRekapListpo,
      meta: { requiresAuth: true }
    },
    {
      path: '/editverifikasiliveig',
      name: 'editverifikasiliveig',
      component: EditVerifikasiLiveIg,
      meta: { requiresAuth: true }
    },
    {
      path: '/summaryreport',
      name: 'summaryreport',
      component: Summaryreport,
      meta: { requiresAuth: true }
    },
    {
      path: '/summaryboughtreport',
      name: 'summaryboughtreport',
      component: Summaryboughtreport,
      meta: { requiresAuth: true }
    },
    {
      path: '/listorderreport',
      name: 'listorderreport',
      component: Listorderreport,
      meta: { requiresAuth: true }
    },
    {
      path: '/packaginglistreport',
      name: 'packaginglistreport',
      component: Packaginglistreport,
      meta: { requiresAuth: true }
    },
    {
      path: '/viewinvoice',
      name: 'viewinvoice',
      component: NewViewInvoice,
      meta: { requiresAuth: false }
    },
    {
      path: '/discount',
      name: 'discount',
      component: Discount,
      meta: { requiresAuth: true }
    },
    {
      path: '/addDiscount',
      name: 'addDiscount',
      component: AddDiscount,
      meta: { requiresAuth: true }
    },
    {
      path: '/editDiscount',
      name: 'editDiscount',
      component: EditDiscount,
      meta: { requiresAuth: true }
    },
    {
      path: '/accept',
      name: 'accept',
      component: Accept,
      meta: { requiresAuth: true }
    },
    {
      path: '/auth',
      name: 'auth',
      component: Auth,
      meta: { requiresAuth: true }
    },
    // Catch-all route using a param with a custom regex (matches any path)
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'dashboard' }
    }
  ]
});


router.beforeEach((to, from, next) => {
  var _Hasync = _Hasync || [];
  _Hasync.push(["Histats.start", "1,4924283,4,0,0,0,00010000"]);
  _Hasync.push(["Histats.fasi", "1"]);
  _Hasync.push(["Histats.track_hits", ""]);
  (function () {
    var hs = document.createElement("script");
    hs.type = "text/javascript";
    hs.async = true;
    hs.src = "//s10.histats.com/js15_as.js";
    (
      document.getElementsByTagName("head")[0] ||
      document.getElementsByTagName("body")[0]
    ).appendChild(hs);
  })();

  const token = Cookies.get('token');  // Retrieve token from cookies

  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      // If no token, redirect to sign-in
      if (to.name !== 'signin') {
        next({ name: 'signin' });
      } else {
        next(); // Already on the sign-in page, so no redirect needed
      }
    } else {
      // If authenticated, allow access to all routes that require auth
      next();
    }
  } else {
    // If route does not require authentication
    if (token && to.name === 'signin') {
      // If user is already authenticated, avoid going to the sign-in page, redirect to dashboard
      next({ name: 'dashboard' });
    } else {
      // If no authentication required, allow access
      next();
    }
  }
});

export default router
