<template>
    <NavBar />
    <header class="lg:px-16 px-5 bg-[#5FBAE6] flex flex-wrap items-center py-3 shadow-md">
        <div class="flex-1 flex justify-between items-center">
            <a href="javascript.void(0)" class="text-3xl font-redHat text-[#F5CA40] font-bold">Produk</a>
        </div>



        <div class="hidden md:flex md:items-center md:w-auto w-full" id="menu">
            <nav>
                <ul class="md:flex items-center justify-between text-base text-gray-700 pt-1 md:pt-0 gap-2">
                    <li>
                        <div class="relative flex items-center p-0 rounded-[20px] w-[250px] max-w-lg h-[50px]">
                            <input type="text" v-model="searchQuery" @input="filterTable"
                                class="rounded-[20px] p-2.5 px-6 w-full h-full focus:border-blue-500 focus:ring focus:ring-blue-200 focus:outline-none"
                                name="search" placeholder="Cari">

                            <button type="submit"
                                class="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 bg-white p-2 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </button>
                        </div>
                    </li>
                    <li>
                        <RouterLink
                            class="md:p-4 py-3  px-0 block flex items-center justify-center w-[258px] h-[50px] rounded-[20px] bg-[#50C996] text-black px-6 py-2.5 font-semibold border border-[#F1EDEA]"
                            to="addproduk">Tambah Baru</RouterLink>
                    </li>

                </ul>
            </nav>
        </div>
    </header>

    <div class="px-5 pt-5 ">
        <table v-if="record.length" id="example">
            <thead class="" style="
                  position: sticky !important;
                    top: 100px !important;
                  background-color: white;
                  z-index: 1;
                ">
                <tr>
                    <th>No.</th>
                    <th>Nama Toko</th>
                    <th>Kode</th>
                    <th>Varian</th>
                    <th>Size Tersedia</th>
                    <th>Harga Modal</th>
                    <th>Harga Jual</th>
                    <th>Tipe</th>
                    <th>Aksi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in record" :key="index" class="tr-table">
                    <td class="custom-td-left">{{ index + 1 }}</td>
                    <td>{{ item.supplier_name }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.color }}</td>
                    <td>{{ item.size }}</td>
                    <td>{{ currencyInput(item.cost_price, currency) }}</td>
                    <td>{{ rupiah(item.normal_price) }}</td>
                    <td>{{ item.type_sale.replace(/_/g, ' ') }}</td>
                    <td class="custom-td-right">
                        <button @click="edit(item)" type="button"
                            class="inline-flex items-center gap-x-2 text-lg font-semibold text-black hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none me-2">
                            <i class="bi bi-pencil-fill"></i>
                        </button>

                        <!-- <button type="button" @click="openModalDelete(index)" class="inline-flex items-center gap-x-2 text-lg font-semibold text-red-500 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                    <i class="bi bi-trash"></i>
                                                </button> -->
                        <button type="button" @click="terminate(item.id)"
                            class="inline-flex items-center gap-x-2 text-lg font-semibold text-red-500 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                            <i class="bi bi-trash"></i>
                        </button>
                    </td>
                    <!-- <td>{{ item.position }}</td>
                                            <td>{{ item.office }}</td>
                                            <td>{{ item.age }}</td> -->
                </tr>


            </tbody>
        </table>

    </div>

    <!-- modal delete -->
    <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        style="z-index:2">
        <!-- Modal content -->
        <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full" @click.stop>
            <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold">Delete Data Produk</h2>
                <button @click="closeModal" class="text-red-600 hover:text-gray-900">
                    ✕
                </button>
            </div>
            <p class="text-gray-700 mb-10">
                Apakah anda yakin ingin menghapus data ini ?
            </p>

            <div class="space-y-3">
                <div aria-hidden="true" class="border-t px-2"></div>
                <div class="button-group flex justify-end">
                    <button @click="closeModal"
                        class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
                        Close
                    </button>
                    <button @click="delete2" class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from "axios";
// import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, rupiah, currencyInput, goPage } from "@/assets/js/function";
import Cookies from "js-cookie";

export default {
    components: {
        NavBar,
    },

    data() {
        return {
            record: [
                // {
                //     kode: "123",
                //     nama_toko: "sample name",
                //     harga_modal: "Rp.300.000",
                //     harga_jual: "Rp.350.000",
                //     warna: "Hitam,Putih",
                //     size_tersedia: "S,M,L,XL",

                // },


            ],
            isModalOpen: false,
            currentDeleteIndex: null,
            config: '',
            // filter
            searchQuery: '',
            dataTable: null,

            currency: '',
        }
    },

    mounted() {
        // Initialize DataTable after the DOM is rendered
        this.initializeDataTable();


    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }

        // console.log(this.config);
        this.index();
    },

    methods: {
        rupiah,
        currencyInput,
        initializeDataTable() {
            // Ensure DataTable is initialized correctly
            // new DataTable('#example');
            // window.$('#example').DataTable();

            if (this.dataTable) {
                this.dataTable.destroy();  // Destroy previous DataTable instance
            }
            this.$nextTick(() => {
                this.dataTable = window.$('#example').DataTable({
                    columnDefs: [{ width: '1%', targets: 0 }],
                    fixedColumns: false,
                    paging: true,
                    scrollCollapse: true,
                    scrollX: true,
                    scrollY: screen.height,
                    bAutoWidth: false,
                });  // Reinitialize DataTable


                window.$('.dt-search').addClass('flex justify-end items-center');
                window.$('select').addClass('mr-3');
                window.$('.dt-paging').addClass('flex justify-end');
                window.$('.dt-search label').addClass('mr-3');
                window.$('.dt-search label').addClass('hidden');
                window.$('.dt-search input').addClass('hidden');
                window.$('.dt-info').addClass('hidden');
                window.$('.dt-length label').text('Entri Per Halaman');
                // window.$('.paginating').addClass('active:bg-blue-300');
            });

            // Optionally: Attach an event listener to default DataTables search input to reflect in custom input
            window.$('.dataTables_filter input').on('input', (e) => {
                this.searchQuery = e.target.value; // Update the custom search input if the default one is used
            });

        },

        filterTable() {
            if (this.dataTable) {
                // Use DataTables' search() API to filter the table based on custom input
                this.dataTable.search(this.searchQuery).draw();

                // Sync the default search input with the custom input
                window.$('.dataTables_filter input').val(this.searchQuery);
            }
        },

        edit(item) {
            // localStorage.setItem('currentData', JSON.stringify(item));
            ShowLoading();
            let formData = new FormData();

            formData.append('id_product', item.id);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'product_detail';
            } else {
                this.api = base_url + 'product_detail';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status === true) {
                        localStorage.setItem('currentData', JSON.stringify(response.data.data[0]));

                        goPage('editproduk');

                        CloseLoading();
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });
        },

        index() {
            ShowLoading();
            let formData = new FormData();

            formData.append('id_preorder', localStorage.getItem('id_preorder'))


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'product';
            } else {
                this.api = base_url + 'product';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {

                    if (response.data.status === true) {
                        this.record = response.data.data

                        this.initializeDataTable();
                        // save to local storage
                        localStorage.setItem('data_supplier', JSON.stringify(response.data.data_supplier));
                        localStorage.setItem('data_category', JSON.stringify(response.data.data_category));
                        localStorage.setItem('data_location', JSON.stringify(response.data.data_location));
                        localStorage.setItem('currency', response.data.currency);
                        this.currency = response.data.currency;
                        // localStorage.setItem('data_product_detail', JSON.stringify(response.data.product_detail));

                        CloseLoading();
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });
        },



        openModalDelete() {
            // this.currentDeleteIndex = index;

            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
            this.id_terminate = null;
        },
        // confirmDelete() {
        //     // Handle the actual deletion here
        //     if (this.currentDeleteIndex !== null) {
        //         this.record.splice(this.currentDeleteIndex, 1);  // Delete the item
        //         this.closeModal();  // Close the modal after deletion
        //     }
        // },

        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);



            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'product_delete';
            } else {
                this.api = base_url + 'product_delete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.record.findIndex((item) => item.id === this.id_terminate); // Find item in the array by ID
                        this.record.splice(index, 1); // Remove the item from the array
                        CloseLoading();
                        this.closeModal(); // Close modal after deletion
                        AlertBottom(response.data.message);

                    } else {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },

        terminate(id) {
            this.id_terminate = id;
            this.openModalDelete();
            // this.tanggal_terminate = new Date();

        },


    },
}
</script>
