<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Produk Baru</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                    <!-- <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nama Produk</label>
                        <input type="text" v-model="currentData.name"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required>
                    </div> -->
                    <div class="">
                        <label class="block font-redHat text-[#8F   8F8F] font-medium font-2xl mb-2">Kode Produk</label>
                        <input type="text" v-model="currentData.code"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required>
                    </div>

                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kategori
                            Produk</label>
                        <!-- <input type="text" v-model="currentData.supplier_name" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"  placeholder="" required> -->
                        <multiselect v-model="currentData.id_category" :options="dataCategory"
                            :custom-label="nameWithLangCategory" placeholder="" label="name" track-by=""
                            :selectLabel="''" :deselectLabel="''" class="multiselect-full-width"></multiselect>
                    </div>

                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Tipe Produk</label>
                        <!-- <input type="text" v-model="currentData.supplier_name" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"  placeholder="" required> -->
                        <multiselect v-model="currentData.type_sale" :options="dataTipeProduk"
                            :custom-label="nameWithLang" placeholder="" label="name" track-by="" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect>
                    </div>

                    <div class="">
                        <div class="flex justify-between align-middle">
                            <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nama Toko</label>
                            <button type="button" @click="AddSupplier()">
                                <i class='bx bx-plus-circle text-xl'></i>

                            </button>
                        </div>
                        <!-- <input type="text" v-model="currentData.supplier_name" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"  placeholder="" required> -->
                        <multiselect v-model="currentData.id_supplier" :options="dataSupplier"
                            :custom-label="nameWithLang" placeholder="" label="name" track-by="" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect>

                    </div>
                    <div class="relative">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Berat Barang</label>
                        <input type="number" step="0.01" v-model="currentData.weight"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="0" required>

                        <!-- Absolute positioned "Kg" text -->
                        <span class="absolute right-6 top-10 text-gray-500">gram</span>
                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Harga Modal</label>
                        <input type="text" v-model="currentData.cost_price"
                            @input="formatForeignCurrency('cost_price', currentData.cost_price)"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                            :placeholder="currency + '. 0,-'" required>
                    </div>
                    <div class="">
                        <!-- <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Harga Jual
                            Normal</label> -->
                        <div class="flex font-redHat text-[#8F8F8F] font-medium font-2xl mb-0 justify-between">
                            <div
                                class="inline-flex items-center space-x-2 rounded p-2 hover:bg-gray-100 accent-teal-600">
                                <input type="checkbox" id="htmlCheckbox" name="languageCheckbox" v-model="normalPrice"
                                    class="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400" />
                                <label for="htmlCheckbox" class="flex w-full space-x-2 text-sm"> Harga Jual
                                    Normal</label>
                            </div>
                            <div
                                class="inline-flex items-center space-x-2 rounded p-2 hover:bg-gray-100 accent-teal-600 ml-auto">
                                <input type="checkbox" id="htmlCheckbox" name="languageCheckbox" v-model="nettPrice"
                                    class="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400" />
                                <label for="htmlCheckbox" class="flex w-full space-x-2 text-sm"> Harga Jual Nett</label>
                            </div>
                        </div>
                        <input type="text" v-model="currentData.normal_price"
                            @input="formatCurrency('normal_price', currentData.normal_price)"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-"
                            required>
                    </div>
                    <!-- <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Harga Jual Net</label>
                        <input type="text" v-model="currentData.net_price"
                            @input="formatCurrency('net_price', currentData.net_price)"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-"
                            required>
                    </div> -->

                </div>


                <div class="relative ">
                    <div
                        class="header-keterangan w-full text-left text-md bg-gray-100 font-semibold rounded-md p-2 text-[20px] h-[45px] mb-2">
                        <h3 class="ms-2">KETERANGAN</h3>
                    </div>
                    <!-- <div class="w-full mb-2">
                        <div class="inline-flex items-center space-x-2 rounded p-2 hover:bg-gray-100 accent-teal-600">
                            <input type="checkbox" id="samePriceCheckbox" v-model="isSamePriceSelected"
                                @change="checkOnlyOne('samePrice')"
                                class="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400" />
                            <label for="samePriceCheckbox" class="flex w-full space-x-2 text-sm text-[#8F8F8F]">Sama
                                Harga</label>
                        </div>

                        <div
                            class="inline-flex items-center space-x-2 rounded p-2 hover:bg-gray-100 accent-teal-600 ml-auto">
                            <input type="checkbox" id="differentPriceCheckbox" v-model="isDifferentPriceSelected"
                                @change="checkOnlyOne('differentPrice')"
                                class="h-4 w-4 rounded border-gray-300 text-teal-600 shadow-sm focus:border-teal-300 focus:ring focus:ring-teal-200 focus:ring-opacity-50 focus:ring-offset-0 disabled:cursor-not-allowed disabled:text-gray-400" />
                            <label for="differentPriceCheckbox"
                                class="flex w-full space-x-2 text-sm text-[#8F8F8F]">Beda Harga</label>
                        </div>
                    </div> -->
                    <!-- tampilan sama harga -->
                    <div v-if="isSamePriceSelected" class="w-full table-responsive sama-harga">
                        <div class="grid grid-cols-6 md:grid-cols-3 lg:grid-cols-3 gap-2 items-center"
                            v-for="(item, index) in currentData.product_detail" :key="index">
                            <div class="me-2">
                                <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Varian</label>
                                <input type="text" v-model="item.color"
                                    class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                                    required>
                            </div>
                            <div class="flex items-center space-x-2 pt-4">
                                <button type="button" @click="addProductDetailRow"
                                    class="rounded-full bg-[#5fbae6] text-white h-[24px] w-[24px] flex items-center justify-center">
                                    <i class="bi bi-plus-lg"></i>
                                </button>
                                <button type="button" @click="removeProductDetail(index)"
                                    class="rounded-full h-[24px] w-[24px] flex items-center justify-center">
                                    <i class="bi bi-trash text-danger"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- tampilan beda harga -->
                    <!-- v-for="(item, index) in currentData.product_detail" :key="index" -->
                    <div v-if="isDifferentPriceSelected"
                        class="w-full table-responsive beda-harga relative overflow-x-auto">
                        <div class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

                            <div>
                                <div class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                    v-for="(item, index) in currentData.product_detail">
                                    <div class="d-flex ">

                                        <div
                                            class=" w-full ps-6 pe-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white  ">
                                            <div class="flex gap-2 w-full ">
                                                <div class="w-full">
                                                    <label
                                                        class="block font-redHat text-[#8F8F8F] font-medium font-2xl text-xs mb-2">Varian</label>
                                                    <input type="text" v-model="item.color"
                                                        class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                                        placeholder="" @keydown.enter="focusNext" required>
                                                </div>

                                                <div class="flex justify-center items-center gap-2 mt-4">
                                                    <button type="button" v-if="checkingLastRow(index)"
                                                        @click="addProductDetailRow" class="icon w-full h-full ">
                                                        <i class="bi bi-plus-circle text-[#5FBAE6]"></i>
                                                    </button>
                                                    <button type="button" @click="openModalDelete(index)"
                                                        class="inline-flex items-center gap-x-2 text-lg font-semibold ms-2 text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none"
                                                        :disabled="currentData.product_detail.length === 1">
                                                        <i class="bi bi-trash"></i>
                                                    </button>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="px-6 py-2  gap-2 w-full">
                                            <div class="d-flex gap-2" v-for="(sizeItem, sizeIndex) in item.sizeqty">

                                                <div class="w-full">
                                                    <label
                                                        class="block font-redHat text-[#8F8F8F] font-medium font-2xl text-xs mb-2">size</label>
                                                    <input type="text" v-model="sizeItem.size"
                                                        @input="sizeChange(index, sizeIndex, 'size', sizeItem.size)"
                                                        class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                                        placeholder="" @keydown.enter="focusNext" required>
                                                </div>

                                                <!-- <div class="w-full">
                                                    <label
                                                        class="block font-redHat text-[#8F8F8F] font-medium font-2xl text-xs mb-2">Add-on
                                                        price</label>
                                                    <input type="text" v-model="sizeItem.price"
                                                        @input="formatCurrency2(index, sizeIndex, 'price', sizeItem.price)"
                                                        @keydown.enter="addProductSizeAndFocus(index, $event);"
                                                        v-show="checkingLastRowProductDetail(index, sizeIndex) && checkingMaxRowProductDetail(index) && !checkSizeDisabled()"
                                                        class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                                        placeholder="Rp. 0,-" required>

                                                    <input type="text" v-model="sizeItem.price"
                                                        @input="formatCurrency2(index, sizeIndex, 'price', sizeItem.price)"
                                                        @keydown.enter="focusNext"
                                                        v-show="!(checkingLastRowProductDetail(index, sizeIndex) && checkingMaxRowProductDetail(index) && !checkSizeDisabled())"
                                                        class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                                        placeholder="Rp. 0,-" required>
                                                </div> -->
                                            </div>


                                        </div>
                                    </div>

                                    <!-- <div class="">
                                        <div class="flex me-2">
                                            <button type="button" v-if="checkingLastRow(index)" @click="addProductDetailRow"
                                                class="icon w-full h-full ">
                                                <i class="bi bi-plus-circle text-[#5FBAE6]"></i>
                                            </button>
                                            <button type="button" @click="openModalDelete(index)"
                                                class="inline-flex items-center gap-x-2 text-lg font-semibold ms-2 text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none"
                                                :disabled="currentData.product_detail.length === 1">
                                                <i class="bi bi-trash"></i>
                                            </button>
                                        </div>

                                    </div> -->
                                </div>

                            </div>
                        </div>
                        <!-- <div class="grid grid-cols-12 md:grid-cols-6 lg:grid-cols-6 gap-2 items-center mb-2" >
                            <div class="me-2">
                                <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Varian</label>
                                <input type="text" v-model="item.color" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required>
                                
                            </div>
                            
                            <div class="flex items-center space-x-2 pt-4">
                                <button type="button" @click="addProductDetail" class="rounded-full bg-[#5fbae6] text-white h-[24px] w-[24px] flex items-center justify-center">
                                    <i class="bi bi-plus-lg"></i>
                                </button>
                                <button type="button" @click="removeProductDetail(index)" class="rounded-full h-[24px] w-[24px] flex items-center justify-center">
                                    <i class="bi bi-trash text-danger"></i>
                                </button>
                            </div>

                            <div class="me-2 w-20">
                                <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Size</label>
                                <input type="text" v-model="item.size" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="FS" disabled>
                            </div>
                            <div class="me-2 col-span-2">
                                <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Penambahan Harga</label>
                                <input type="text" v-model="item.price" @input="formatCurrency2('price', item.price)" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-" required>
                            </div>
                            
                            
                        </div>

                        <div class="grid grid-cols-12 md:grid-cols-6 lg:grid-cols-6 gap-2 items-center mb-2" >

                            <div class="me-2 col-start-3 w-20">
                                
                                <input type="text" v-model="item.size" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="XS" disabled>
                            </div>
                            <div class="me-2 col-span-2">
                                
                                <input type="text" v-model="item.price" @input="formatCurrency2('price', item.price)" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-" required>
                            </div>
                            
                            
                        </div>
                        <div class="grid grid-cols-12 md:grid-cols-6 lg:grid-cols-6 gap-2 items-center mb-2" >

                            <div class="me-2 col-start-3 w-20">
                                
                                <input type="text" v-model="item.size" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="S" disabled>
                            </div>
                            <div class="me-2 col-span-2">
                                
                                <input type="text" v-model="item.price" @input="formatCurrency2('price', item.price)" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-" required>
                            </div>
                            
                            
                        </div>
                        <div class="grid grid-cols-12 md:grid-cols-6 lg:grid-cols-6 gap-2 items-center mb-2" >

                            <div class="me-2 col-start-3 w-20">
                                
                                <input type="text" v-model="item.size" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="M" disabled>
                            </div>
                            <div class="me-2 col-span-2">
                                
                                <input type="text" v-model="item.price" @input="formatCurrency2('price', item.price)" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-" required>
                            </div>
                            
                            
                        </div>
                        <div class="grid grid-cols-12 md:grid-cols-6 lg:grid-cols-6 gap-2 items-center mb-2" >

                            <div class="me-2 col-start-3 w-20">
                                
                                <input type="text" v-model="item.size" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="L" disabled>
                            </div>
                            <div class="me-2 col-span-2">
                                
                                <input type="text" v-model="item.price" @input="formatCurrency2('price', item.price)" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-" required>
                            </div>
                            
                            
                        </div>
                        <div class="grid grid-cols-12 md:grid-cols-6 lg:grid-cols-6 gap-2 items-center mb-2" >

                            <div class="me-2 col-start-3 w-20">
                                
                                <input type="text" v-model="item.size" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="XL" disabled>
                            </div>
                            <div class="me-2 col-span-2">
                                
                                <input type="text" v-model="item.price" @input="formatCurrency2('price', item.price)" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-" required>
                            </div>
                            
                            
                        </div>
                        <div class="grid grid-cols-12 md:grid-cols-6 lg:grid-cols-6 gap-2 items-center mb-2" >

                            <div class="me-2 col-start-3 w-20">
                                
                                <input type="text" v-model="item.size" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="2XL" disabled>
                            </div>
                            <div class="me-2 col-span-2">
                                
                                <input type="text" v-model="item.price" @input="formatCurrency2('price', item.price)" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-" required>
                            </div>
                        </div> -->
                    </div>

                </div>




                <!-- <div class="relative overflow-x-auto">
                    
                </div> -->
                <!-- <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Varian
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Keterangan
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    QTY
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Biaya Tambahan
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    Aksi
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                v-for="(item, index) in currentData.product_detail">
                                <th scope="row"
                                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <input type="text" v-model="item.color"
                                        class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                                        @keydown.enter="focusNext" required>
                                </th>
                                <td class="px-6 py-2 d-flex gap-2" v-for="(sizeItem, sizeIndex) in item.sizeqty">
                                    <div class="w-full">
                                        <label
                                            class="block font-redHat text-[#8F8F8F] font-medium font-2xl text-xs mb-2">size</label>
                                        <input type="text" v-model="sizeItem.size"
                                            @input="sizeChange(index, sizeIndex, 'size', sizeItem.size)"
                                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                                            @keydown.enter="focusNext" required>
                                    </div>
                                    <div>
                                        <label
                                            class="block font-redHat text-[#8F8F8F] font-medium font-2xl text-xs mb-2">qty</label>
                                        <input type="number" v-model="sizeItem.qty"
                                            @input="qtyChange(index, sizeIndex, 'qty', sizeItem.qty)"
                                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                                            @keydown.enter="focusNext2(index, sizeIndex, $event)" required>

                                    </div>
                                    <div class="w-full">
                                        <label
                                            class="block font-redHat text-[#8F8F8F] font-medium font-2xl text-xs mb-2">Add-on
                                            price</label>
                                        <input type="text" v-model="sizeItem.price"
                                            @input="formatCurrency2(index, sizeIndex, 'price', sizeItem.price)"
                                            @keydown.enter="addProductSizeAndFocus(index, $event);"
                                            v-show="checkingLastRowProductDetail(index, sizeIndex) && checkingMaxRowProductDetail(index) && !checkSizeDisabled()"
                                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                            placeholder="Rp. 0,-" required>

                                        <input type="text" v-model="sizeItem.price"
                                            @input="formatCurrency2(index, sizeIndex, 'price', sizeItem.price)"
                                            @keydown.enter="focusNext"
                                            v-show="!(checkingLastRowProductDetail(index, sizeIndex) && checkingMaxRowProductDetail(index) && !checkSizeDisabled())"
                                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                            placeholder="Rp. 0,-" required>
                                    </div>

                                    <div class="flex flex-column justify-center">
                                        <div v-show="checkingLastRowProductDetail(index, sizeIndex) && checkingMaxRowProductDetail(index) && !checkSizeDisabled()"
                                            @click="addProductSize(index);" class="cursor-pointer"> <i
                                                class="bi bi-plus-circle text-[15px] text-[#5FBAE6]"></i></div>

                                        <div v-show="checkingMinRowProductDetail(index) && !checkSizeDisabled()"
                                            @click="deleteProductSize(index, sizeIndex);" class="cursor-pointer"><i
                                                class="bi bi-trash text-danger"></i></div>
                                    </div>
                                </td>

                                <td class="">
                                    <div class="flex me-2">
                                        <button type="button" v-if="checkingLastRow(index)" @click="addProductDetailRow"
                                            class="icon w-full h-full ">
                                            <i class="bi bi-plus-circle text-[#5FBAE6]"></i>
                                        </button>
                                        <button type="button" @click="openModalDelete(index)"
                                            class="inline-flex items-center gap-x-2 text-lg font-semibold ms-2 text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none"
                                            :disabled="currentData.product_detail.length === 1">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>

                                </td>
                            </tr>

                        </tbody>
                    </table> -->


                <!-- <div class="grid grid-cols-12 gap-4 mb-3" v-for="(item, index) in currentData.product_detail"
                    :key="index"> -->



                <!-- <div class=" col-span-3 me-3">
                        <label for="warna"
                            class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Warna</label>
                        <input type="text" v-model="item.color"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="warna" placeholder="">
                    </div>
                    <div class=" col-span-8 ">
                        <div class="block mb-3">
                            <label for="size"
                                class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Size</label>
                            <div class="inline-block" v-for="(sizeItem, sizeIndex) in item.sizeqty" :key="sizeIndex">
                                <div class="d-flex">
                                    <input type="text" v-model="sizeItem.size"
                                        class=" w-14 border-1 border-[#5FBAE6] rounded-md p-2 me-2 text-xl text-center text-black"
                                        name="size" placeholder="" :disabled="checkSizeDisabled()">
                                    <div class="d-flex flex-column">
                                        <div v-show="checkingLastRowProductDetail(index, sizeIndex) && checkingMaxRowProductDetail(index) && !checkSizeDisabled()"
                                            @click="addProductSize(index);" class="cursor-pointer"> <i
                                                class="bi bi-plus-circle text-[15px] text-[#5FBAE6]"></i></div>
                                        <div v-show="checkingLastRowProductDetail(index, sizeIndex) && checkingMinRowProductDetail(index) && !checkSizeDisabled()"
                                            @click="deleteProductSize(index, sizeIndex);" class="cursor-pointer"><i
                                                class="bi bi-trash text-danger"></i>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div class="block">
                            <label for="size"
                                class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Qty</label>
                            <div class="inline-block" v-for="(sizeItem, sizeIndex) in item.sizeqty" :key="sizeIndex">
                                <input type="number" min="0" v-model="sizeItem.qty"
                                    class=" w-14 border-1 border-[#5FBAE6] rounded-md p-2 me-2 text-xl text-center"
                                    name="size" placeholder="">
                            </div>

                        </div>


                    </div> -->

                <!-- <div class="flex items-center justify-center">
                        <button type="button" v-if="checkingLastRow(index)" @click="addProductDetailRow"
                            class="icon w-full h-full ">
                            <i class="bi bi-plus-circle text-[40px] text-[#5FBAE6]"></i>
                        </button>
                        <button type="button" @click="openModalDelete(index)"
                            class="inline-flex items-center gap-x-2 text-lg font-semibold ms-2 text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none"
                            :disabled="currentData.product_detail.length === 1">
                            <i class="bi bi-trash"></i>
                        </button>
                    </div> -->

                <!-- </div> -->




            </div>

            <div class="mb-5 space-x-5 flex justify-end px-10">
                <button type="button" @click="Cancel"
                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">
                    Batal</button>
                <button type="submit"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
            </div>
        </form>


    </div>
    <!-- modal delete -->
    <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <!-- Modal content -->
        <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full" @click.stop>
            <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold">Delete Produk</h2>
                <button @click="closeModal" class="text-red-600 hover:text-gray-900">
                    ✕
                </button>
            </div>
            <p class="text-gray-700 mb-10">
                Apakah anda yakin ingin menghapus data ini ?
            </p>

            <div class="space-y-3">
                <div aria-hidden="true" class="border-t px-2"></div>
                <div class="button-group flex justify-end">
                    <button @click="closeModal"
                        class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
                        Close
                    </button>
                    <button @click="confirmDelete"
                        class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.text-xs {
    font-size: 11px !important;
}
</style>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, rupiahInput, rupiahoutput, currencyInput } from "@/assets/js/function";
// import {ShowLoading, CloseLoading} from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect';
import { setLocale } from 'yup';


export default {
    components: {
        NavBar,
        Multiselect,

    },

    data() {
        return {
            currentData: {
                cost_price: "",
                normal_price: "",
                net_price: "",
                product_detail: [
                ],
                type_sale: { name: "LIVE IG", value: "LIVE_IG" },
            },
            dataTipeProduk: [
                { name: "STORY IG", value: "STORY_IG" },
                { name: "LIVE IG", value: "LIVE_IG" },
            ],
            dataSupplier: [],
            dataCategory: [],
            isModalOpen: false,
            currentDeleteIndex: null,
            statusLoad: false,
            isSamePriceSelected: false,
            isDifferentPriceSelected: true,
            currency: "",

            normalPrice: true,
            nettPrice: false,
            // newRecord: '',
        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }

        this.dataSupplier = JSON.parse(localStorage.getItem('data_supplier'));
        this.dataCategory = JSON.parse(localStorage.getItem('data_category'));
        this.currency = localStorage.getItem('currency');

        if (localStorage.getItem('product_size') != null && localStorage.getItem('product_size') != undefined && localStorage.getItem('product_size') != '' && localStorage.getItem('product_size') != '[]') {
            const product_default = JSON.parse(localStorage.getItem('product_size'));
            let item_prepare = {};
            item_prepare.color = '';
            item_prepare.sizeqty = product_default;
            this.currentData.product_detail.push(item_prepare);

        } else {

            this.currentData.product_detail.push({
                color: '',
                sizeqty: [
                    { size: "FS", qty: 0, price: 0 },
                ]
            })



        }

        // console.log(this.config);
        // this.index();
    },

    updated() {
        this.statusLoad = true;
    },
    watch: {
        'currentData.product_detail': {
            handler(newValue) {
                // Deep clone of the array to avoid affecting original data
                let size_fix = [];
                if (newValue && newValue[0] && Array.isArray(newValue[0].sizeqty)) {
                    let clonedSizeQty = JSON.parse(JSON.stringify(newValue[0].sizeqty));
                    // You can perform any operations on clonedSizeQty
                    clonedSizeQty.forEach((item) => {
                        let item_prepare = { ...item };  // clone each item
                        item_prepare.qty = 0;
                        size_fix.push(item_prepare);
                    });
                }
                if (this.statusLoad == true) {
                    localStorage.setItem('product_size', JSON.stringify(size_fix));
                }
            },
            deep: true
        },
        'currentData.id_category': {
            handler(newValue) {
                if (this.isSamePriceSelected) {
                    this.currentData.product_detail.forEach((item, index) => {
                        item.sizeqty = [{ size: "FS", qty: 0, price: 0 }];
                    });

                } else {
                    if (newValue == undefined) {
                        this.currentData.product_detail.forEach((item, index) => {
                            item.sizeqty = [{ size: "FS", qty: 0, price: 0 }];
                        });
                    } else {
                        const id = newValue.id;
                        const index = this.dataCategory.findIndex((record) => record.id == id);
                        this.currentData.product_detail = [];

                        const sizeArray = this.dataCategory[index].size.split(", ");
                        const dataSize = sizeArray.map(size => ({ size: size, qty: 0, price: 0 }));

                        this.currentData.product_detail.push({
                            color: '',
                            sizeqty: dataSize
                        })
                    }
                }

            }
        },
        normalPrice: {
            handler(newValue) {
                if (newValue == false) {
                    this.nettPrice = true;
                } else {
                    this.nettPrice = false;
                }
            }
        },
        nettPrice: {
            handler(newValue) {
                if (newValue == false) {
                    this.normalPrice = true;
                } else {
                    this.normalPrice = false;
                }
            }
        }

    },

    computed: {
        isSamePrice() {
            return this.selectedCheckbox === "samePrice";
        },
        isDifferentPrice() {
            return this.selectedCheckbox === "differentPrice";
        },
    },

    methods: {
        checkOnlyOne(selected) {
            if (selected === "samePrice") {
                this.isSamePriceSelected = true;
                this.isDifferentPriceSelected = false;
                this.currentData.product_detail.forEach((item, index) => {
                    item.sizeqty = [{ size: "FS", qty: 0, price: 0 }];
                });
            } else if (selected === "differentPrice") {
                this.isSamePriceSelected = false;
                this.isDifferentPriceSelected = true;

                let id = 0;
                if (this.currentData.id_category != undefined) {
                    id = this.currentData.id_category.id;
                }


                const index = this.dataCategory.findIndex((record) => record.id == id);
                if (index > -1) {

                    const sizeArray = this.dataCategory[index].size.split(", ");
                    const dataSize = sizeArray.map(size => ({ size: size, qty: 0, price: 0 }));


                    this.currentData.product_detail.forEach((item, index) => {
                        item.sizeqty = dataSize
                    });

                } else {

                    this.currentData.product_detail.forEach((item, index) => {
                        item.sizeqty = [{ size: "FS", qty: 0, price: 0 }];
                    });

                }


            }
        },
        focusNext(event) {
            const inputs = Array.from(document.querySelectorAll('input')); // Get all input elements
            const currentIndex = inputs.indexOf(event.target); // Find the current input's index

            if (currentIndex !== -1 && inputs[currentIndex + 1]) {
                // Focus on the next input if it exists
                inputs[currentIndex + 1].focus();
            }
        },
        focusNext2(index, index2, event) {

            let plus = 1;
            if (this.checkingLastRowProductDetail(index, index2) == false) {
                plus = 2;
            }

            const inputs = Array.from(document.querySelectorAll('input')); // Get all input elements
            const currentIndex = inputs.indexOf(event.target); // Find the current input's index

            if (currentIndex !== -1 && inputs[currentIndex + plus]) {
                // Focus on the next input if it exists
                inputs[currentIndex + plus].focus();
            }
        },
        // addProductDetail() {
        //     this.currentData.product_detail.push({
        //         color: "",
        //     });
        // },
        removeProductDetail(index) {
            if (this.currentData.product_detail.length > 1) {
                this.currentData.product_detail.splice(index, 1);
            }
        },
        AddSupplier() {
            localStorage.setItem('historyPage', 'addproduk');
            goPage('addsupplier');
        },
        handleCategoryChange() {
            this.dataCategory
        },
        Cancel() {
            localStorage.removeItem('product_size', 'test');
            goPage('produk');

        },
        nameWithLang({ name }) {
            return `${name}`
        },
        nameWithLangCategory({ name }) {
            return `${name}`
        },
        formatCurrency(label, number) {
            this.currentData[label] = rupiahInput(number);
        },
        formatForeignCurrency(label, number) {
            this.currentData[label] = currencyInput(number, this.currency);
        },
        formatCurrency2(index, sizeIndex, label, number) {
            this.currentData.product_detail[index].sizeqty[sizeIndex][label] = rupiahInput(number);
        },
        qtyChange(index, sizeIndex, label, number) {
            // console.log(this.currentData.product_detail);
            // console.log(index);
            // console.log(this.currentData.product_detail[index]);
            // const size = this.currentData.product_detail[index].sizeqty[sizeIndex].size;

            this.currentData.product_detail[index].sizeqty[sizeIndex][label] = number

            // this.currentData.product_detail.forEach((item) => {

            //     item.sizeqty.forEach((itemsize, indexsize) => {
            //         // const price = itemsize.price;
            //         if (itemsize.size == size) {

            //             itemsize.qty = number;
            //             // itemsize.price = 1;
            //         }

            //     });
            // });

        },
        sizeChange(index, sizeIndex, label, number) {
            // const size = this.currentData.product_detail[index].sizeqty[sizeIndex].size;
            // this.currentData.product_detail[index].sizeqty[sizeIndex][label] = rupiahInput(number);
            this.currentData.product_detail.forEach((item) => {
                item.sizeqty.forEach((itemsize, indexsize) => {
                    // const price = itemsize.price;
                    if (indexsize == sizeIndex) {

                        itemsize.size = number;
                        // itemsize.price = 1;
                    }

                });
            });
        },
        checkSizeDisabled() {
            if (this.currentData.product_detail.length == 1) {
                return false;
            } else {
                return true;

            }
        },
        checkingLastRowProductDetail(index, sizeIndex) {
            if (sizeIndex == this.currentData.product_detail[index].sizeqty.length - 1) {
                // this.records[index].isAdded = true;
                return true;

                // this.records.splice(index + 1, 0, );
            } else {
                // this.records[index].isAdded = false;
                return false;

            }
        },
        checkingMaxRowProductDetail(index) {

            if (this.currentData.product_detail[index].sizeqty.length == 13) {
                // this.records[index].isAdded = true;
                return false;

                // this.records.splice(index + 1, 0, );
            } else {
                // this.records[index].isAdded = false;
                return true;

            }
        },
        checkingMinRowProductDetail(index) {
            if (this.currentData.product_detail[index].sizeqty.length == 1) {

                return false;


            } else {

                return true;

            }
        },
        addProductSize(index) {
            this.currentData.product_detail[index].sizeqty.push({
                size: '',
                qty: 0
            });

        },
        addProductSizeAndFocus(index, event) {
            this.currentData.product_detail[index].sizeqty.push({
                size: '',
                qty: 0
            });


            this.$nextTick(() => {
                // Get all input elements
                const inputs = Array.from(document.querySelectorAll('input'));
                const currentIndex = inputs.indexOf(event.target);

                if (currentIndex !== -1 && inputs[currentIndex + 1]) {
                    inputs[currentIndex + 1].focus();
                }
            });

        },
        deleteProductSize(index, sizeIndex) {
            this.currentData.product_detail[index].sizeqty.splice(sizeIndex, 1);
        },
        addProductDetailRow() {
            this.currentData.product_detail.push({
                color: '',
                sizeqty: JSON.parse(localStorage.getItem('product_size'))
            });
        },
        checkingLastRow(index) {
            if (index == this.currentData.product_detail.length - 1) {
                // this.records[index].isAdded = true;
                return true;

                // this.records.splice(index + 1, 0, );
            } else {
                // this.records[index].isAdded = false;
                return false;

            }
        },
         findDuplicateIndices(productDetails) {
    let seen = new Map();
    let duplicates = [];

    productDetails.forEach((item, index) => {
        let key = item.color.trim();
        if (seen.has(key)) {
            duplicates.push(index);
        } else {
            seen.set(key, index);
        }
    });

    return duplicates;
},
        
        insert() {

            if (this.findDuplicateIndices(this.currentData.product_detail)>0){
                AlertPopup("error", "", "Terdapat varian yang sama", 1500, false); // Show alert if id_supplier is missing
                return true;
            }

            if (rupiahoutput(this.currentData.normal_price) == 0) {
                AlertPopup("error", "", "Harga Jual harus lebih dari 0", 1500, false); // Show alert if id_supplier is missing
                return;
            }
            // Check if id_supplier is null or undefined
            if (!this.currentData.id_supplier || !this.currentData.id_supplier.id) {
                AlertPopup("error", "", "Nama toko harus diisi", 1500, false); // Show alert if id_supplier is missing
                return; // Stop the function from proceeding
            }
            // if (!this.currentData.id_category || !this.currentData.id_category.id) {
            //     AlertPopup("error", "", "Kategori produk harus dipilih", 1500, false); // Show alert if id_category is missing
            //     return; // Stop the function from proceeding
            // }
            if (!this.currentData.type_sale || !this.currentData.type_sale.value) {
                AlertPopup("error", "", "Tipe produk harus dipilih", 1500, false); // Show alert if id_category is missing
                return; // Stop the function from proceeding
            }

            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            formData.delete("id_supplier");
            formData.append("id_supplier", this.currentData.id_supplier.id)

            formData.delete("id_category");
            if (this.currentData.id_category != undefined) {
                formData.append("id_category", this.currentData.id_category.id)
            }

            formData.delete("type_sale");
            formData.append("type_sale", this.currentData.type_sale.value)

            

            let data = [];
            this.currentData.product_detail.forEach((item, index) => {
                let item_prepare = { ...item };
                item.sizeqty.forEach((itemsize, indexsize) => {
                    const price = itemsize.price;
                    item_prepare.sizeqty[indexsize].price = rupiahoutput(price);
                });
                data.push(item_prepare);
            });

            formData.delete("product_detail");
            formData.append("product_detail", JSON.stringify(data));
            formData.delete("cost_price");
            formData.append('cost_price', rupiahoutput(this.currentData.cost_price));
            formData.delete("normal_price");
            formData.append('normal_price', rupiahoutput(this.currentData.normal_price));
            formData.append('id_preorder', localStorage.getItem('id_preorder'));

            if (this.isSamePriceSelected == true) {
                formData.append("type", 'SAME_PRICE');
            } else {
                formData.append("type", 'DIFFERENT_PRICE');
            }

            if (this.normalPrice == true) {
                formData.append("type_price", 'NORMAL_PRICE');
            } else {
                formData.append("type_price", 'NET_PRICE');
            }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'product_insert';
            } else {
                this.api = base_url + 'product_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        goPage("produk");
                        localStorage.removeItem('product_size');
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
        openModalDelete(index) {
            this.currentDeleteIndex = index;

            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        confirmDelete() {
            // Handle the actual deletion here
            if (this.currentDeleteIndex !== null) {
                this.currentData.product_detail.splice(this.currentDeleteIndex, 1);  // Delete the item
                this.closeModal();  // Close the modal after deletion
            }
        },
    },
}
</script>