<template>
  <NavBar />
  <header class="lg:px-16 px-5 bg-white flex flex-wrap items-center py-3">
    <div class="flex-1 flex justify-between items-center pt-3">
      <a href="#" class="text-2xl font-redHat text-[#5FBAE6] font-bold">Laporan Penjualan</a>
    </div>
  </header>
  <div class="px-5 py-4">
    <form @submit.prevent>
      <div class="card rounded-[10px] border-1 border-[#5FBAE6]/30 p-4 h-full bg-[#FAF8F8]">
        <div class="grid grid-cols-12 ">
          <div class="col-span-4 me-3">
            <label for="" class="block font-redHat text-gray-400 mb-2">PO Batch <span
                class="text-red-600">*</span></label>
            <input type="text" v-model="currentData.preorder[0].no_po"
              class="block w-full border-1 border-[#e8e8e8] rounded-md p-2" placeholder="" disabled>
          </div>
          <div class="col-span-4 me-3">
            <label for="" class="block font-redHat text-gray-400 mb-2">Periode <span
                class="text-red-600">*</span></label>
            <input type="text" v-model="currentData.periode"
              class="block w-full border-1 border-[#e8e8e8] rounded-md p-2" placeholder="" disabled>
          </div>
          <div class="col-span-4 me-3">
            <label for="" class="block font-redHat text-gray-400 mb-2">Admin <span class="text-red-600">*</span></label>
            <input type="text" v-model="currentData.nama_admin"
              class="block w-full border-1 border-[#e8e8e8] rounded-md p-2" placeholder="" disabled>
          </div>
          <div class="col-span-4 me-3 mt-3">
            <div class="flex items-center justify-between">
              <label for="" class="block font-redHat text-gray-400 mb-2 me-2">Instagram <span
                  class="text-red-600">*</span></label>
              <!-- <button type="button" @click="addNewCustomer" class="text-[#5FBAE6] text-[22px]"><i
                                  class="bi bi-plus-circle-fill"></i></button>  -->
            </div>
            <multiselect v-model="currentData.id_customer" :options="currentData.customer"
              :custom-label="instagramWithLang" placeholder="" label="name" track-by="" :selectLabel="''"
              :deselectLabel="''" class="multiselect-full-width" disabled></multiselect>
          </div>
          <div class="col-span-4 me-3 mt-3">
            <label for="" class="block font-redHat text-gray-400 mb-2">Nama Customer <span
                class="text-red-600">*</span></label>
            <input type="text" class="block w-full border-1 border-[#e8e8e8] rounded-md p-2" placeholder=""
              v-model="currentData.name" disabled>
          </div>
          <div class="col-span-4 me-3 mt-3">
            <label for="" class="block font-redHat text-gray-400 mb-2">No. Whatsapp <span
                class="text-red-600">*</span></label>
            <input type="text" class="block w-full border-1 border-[#e8e8e8] rounded-md p-2" placeholder=""
              v-model="currentData.whatsapp" disabled>
          </div>
          <!-- alamat Pengiriman-->
          <div class="col-span-12 mt-3">
            <label for="" class="block font-redHat text-gray-400 mb-2">Alamat Pengiriman<span
                class="text-red-600">*</span></label>
            <div class="flex gap-3">
              <input type="text" class="block w-full border-1 border-[#e8e8e8] rounded-md p-2"
                placeholder="Alamat Pengiriman" v-model="currentData.address" disabled>
              <!-- <input type="text" class="block w-full border-1 border-[#e8e8e8] rounded-md p-2" placeholder="Provinsi"
                v-model="currentData.province" disabled>
              <input type="text" class="block w-full border-1 border-[#e8e8e8] rounded-md p-2"
                placeholder="Kota/Kabupaten" v-model="currentData.city" disabled>
              <input type="text" class="block w-full border-1 border-[#e8e8e8] rounded-md p-2" placeholder="Kecamatan"
                v-model="currentData.district" disabled>
              <input type="text" class="block w-full border-1 border-[#e8e8e8] rounded-md p-2" placeholder="Kelurahan"
                v-model="currentData.sub_district" disabled>
              <input type="text" class="block w-full border-1 border-[#e8e8e8] rounded-md p-2" placeholder="kode pos"
                v-model="currentData.pos_code" disabled> -->
            </div>
          </div>
          <!-- List Orderan -->
          <div class="col-span-12 mt-4">
            <div class="flex justify-between items-center ">
              <label for="" class="block font-redHat text-black ">List Orderan</label>
              <!-- <button type="button" @click="addNewProduct" class="text-[#5FBAE6] text-[22px]"><i
                                  class="bi bi-plus-circle-fill"></i></button>  -->
            </div>
            <table class="text-sm text-left rtl:text-right text-[#8F8F8F] mb-4 w-auto">
              <thead class="text-xs text-gray-700 bg-[#F1F1F1] shadow-sm shadow-[#8F8F8F] mb-5">
                <tr>
                  <th scope="col" class="px-6 py-3 font-normal text-[#8F8F8F] font-redHat rounded-l-sm   text-center">
                    No.
                  </th>
                  <th scope="col" class="px-6 py-3 font-normal text-[#8F8F8F] font-redHat text-center">
                    Tanggal
                  </th>
                  <th scope="col" class="px-6 py-3 font-normal text-[#8F8F8F] font-redHat    text-center">
                    Kode Produk
                  </th>
                  <th scope="col" class="px-6 py-3 font-normal text-[#8F8F8F] font-redHat    text-center">
                    Varian
                  </th>
                  <th scope="col" class="px-6 py-3 font-normal text-[#8F8F8F] font-redHat    text-center">
                    Size
                  </th>
                  <th scope="col" class="px-6 py-3 font-normal text-[#8F8F8F] font-redHat   text-center">
                    Jenis Penjualan
                  </th>
                  <th scope="col" class="px-6 py-3 font-normal text-[#8F8F8F] font-redHat text-center">
                    Qty
                  </th>
                  <th scope="col" class="px-6 py-3 font-normal text-[#8F8F8F] font-redHat text-center  ">
                    Order Status
                  </th>
                  <th scope="col" class="px-6 py-3 font-normal text-[#8F8F8F] font-redHat text-center  ">
                    Harga Satuan
                  </th>
                  <th scope="col" class="px-6 py-3 font-normal text-[#8F8F8F] font-redHat rounded-r-sm text-center">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white border-1 border-spacing-1 border-[#e8e8e8] border-opacity-50 "
                  v-for="(item, index) in currentData.list_order" :key="index" v-show="currentData.id_customer != null">
                  <td scope="row" class="px-2 py-2 font-medium text-gray-900 font-redHat  whitespace-nowrap text-center"
                    v-if="item.qty > 0">
                    <span class="tooltip2" :style="{ '--tooltip-content': `'${item.info}'` }" v-if="item.info != null">
                      <i class="bi bi-info text-blue-600"></i>
                    </span>
                    {{ index + 1 }}
                    <button type="button" v-if="currentData.list_order.length > 1" @click="deleterow(index)"
                      class="cursor-pointer" :disabled="allowEdit(item)" :class="{ 'opacity-50': allowEdit(item) }">
                      <!-- <i class="bi bi-trash text-red-400"></i> -->
                    </button>
                  </td>
                  <td class="px-2 py-2 text-gray-900 font-redHat border-1" v-if="item.qty > 0">
                    <input type="date" class="w-full h-[30px]" id="listOrderTable" v-model="item.date" placeholder=""
                      :disabled="allowEdit(item)">
                  </td>
                  <td class="px-2 py-2 text-gray-900 font-redHat border-1" v-if="item.qty > 0">

                    <multiselect v-model="item.id_product" :options="currentProductList"
                      :custom-label="nameWithLangProduct" placeholder="Pilih product" label="name" :track-by="'id'"
                      :selectLabel="''" :deselectLabel="''" :class="{ 'name_product': item.type_price == 'NET_PRICE' }"
                      @open="beforeProductChange(index)" @update:modelValue="afterProductChange(index)"
                      open-direction="bottom" :disabled="allowEdit(item)"> </multiselect>
                  </td>
                  <td class="px-2 py-2 text-gray-900 font-redHat border-1" v-if="item.qty > 0">
                    <multiselect v-model="item.color" :options="currentDataColorList" :custom-label="colorWithLang"
                      placeholder="Pilih product" label="name" :track-by="'color'" :selectLabel="''" :deselectLabel="''"
                      class="" @open="openColorChange(index)" @update:modelValue="handleColorChange(index)"
                      open-direction="bottom" :disabled="allowEdit(item)">
                    </multiselect>
                  </td>
                  <td class="px-2 py-2 text-gray-900 font-redHat border-1" v-if="item.qty > 0">
                    <multiselect v-model="item.size" :options="currentDataSizeList" :custom-label="sizeWithLang"
                      placeholder="Pilih product" label="name" :track-by="'size'" :selectLabel="''" :deselectLabel="''"
                      class="" @open="handleColorChange(index)" open-direction="bottom" :disabled="allowEdit(item)">
                    </multiselect>
                  </td>
                  <td class="px-2 py-2 text-gray-900 font-redHat border-1" v-if="item.qty > 0">
                    <multiselect v-model="item.type" :options="jenisPenjualanList" :custom-label="nameWithLang"
                      placeholder="Pilih jenis" label="name" :track-by="'name'" :selectLabel="''" :deselectLabel="''"
                      class="" @open="handleColorChange(index)" open-direction="bottom" disabled>
                    </multiselect>

                  </td>
                  <td class="px-2 py-2 text-gray-900 font-redHat border-1 " v-if="item.qty > 0">
                    <input type="number" class="w-full h-[30px] text-center" id="listOrderTable" v-model="item.qty"
                      placeholder="0" @input="afterProductChange(index)" @keydown.enter="focusNext($event, index);"
                      :disabled="allowEdit(item)">
                  </td>
                  <td class="px-2 py-2 text-gray-900 font-redHat border-1 " v-if="item.qty > 0">
                    <multiselect v-model="item.order_status" :options="StatusPesananList" placeholder="pilih"
                      label="name" :custom-label="nameWithLang" :selectLabel="''" :deselectLabel="''"
                      class="multiselect-full-width" open-direction="bottom" :disabled="allowEdit(item)">
                    </multiselect>
                  </td>
                  <td class="px-2 py-2 text-gray-900 font-redHat border-1 text-center" v-if="item.qty > 0">
                    <span>
                      {{ item.normal_price }}
                    </span>
                  </td>
                  <td class="px-2 py-2 text-gray-900 font-redHat border-1 text-center" v-if="item.qty > 0">
                    <span>
                      {{ item.total }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
              <div class="">
                Total
              </div>
              <div class="text-[#8F8F8F]">
                <span v-if="currentData.subtotal > 0">{{ rupiah(currentData.subtotal) }}</span>
                <span v-else>Rp. 0,-</span>

              </div>
            </div>
            <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
              <div class="">
                Diskon
              </div>
              <!-- <div class="flex items-center ms-auto me-28">
                <div class="border border-gray-500 w-[45px] h-[45px] flex items-center justify-center rounded-md me-2">
                  Rp.</div>
                <input type="text" class="w-full border-1 border-gray-500 rounded-md p-2" placeholder="0,-">
              </div> -->
              <div class="text-[#8F8F8F]">
                <span v-if="currentData.diskon > 0">{{ rupiah(currentData.diskon) }}</span>
                <span v-else>Rp. 0,-</span>
              </div>
            </div>
            <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
              <div class="">
                Total Barang
              </div>
              <div class="text-[#8F8F8F]">
                {{ currentData.qty }}
              </div>
            </div>
            <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
              <div class="">
                Total Berat
              </div>
              <div class="text-[#8F8F8F]">
                <!-- <span class="tooltip2" :style="{ '--tooltip-content': `'${currentData.total_weight / 1000} '` }"> -->
                <span>{{ currentData.total_weight / 1000 + ' kg -> ' }}</span>
                <span v-if="currentData.total_weight > 0">
                  {{ currentData.expedition?.name === 'LION BIG PACK' && currentData.total_weight < 10000 ? 10 :
                    Math.floor(currentData.total_weight / 1000) + (currentData.total_weight % 1000 >= 300 ? 1 : 0) ||
                    (currentData.total_weight > 0 && currentData.total_weight < 1000 ? 1 : 0) }} kg</span>
                      <!-- <span v-if="currentData.total_weight > 0">
                        {{ Math.ceil(currentData.total_weight / 1000) }} kg</span> -->
                      <!-- <span v-else>0 Kg</span> -->
                      <!-- </span> -->

                      <!-- <span class="tooltip2" :style="{ '--tooltip-content': `'${1200 / 1000 } '` }">
                
                  <span v-if="1.3 > 0">
                    {{ Math.floor(1200 / 1000) + (1200 % 1000 >= 300 ? 1 : 0) }} kg</span>
                  
                  <span v-else>0 Kg</span>
                </span> -->
              </div>
            </div>
            <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
              <div class="">
                Total Ongkos Kirim
              </div>
              <div class="flex items-center ms-auto me-28 gap-3">
                <multiselect v-model="currentData.expedition" :options="ekspedisiList" :custom-label="nameWithLang"
                  placeholder="Pilih jenis ekspedisi" label="name" :track-by="'name'" :selectLabel="''"
                  :deselectLabel="''" class="" open-direction="bottom" :disabled="currentData.subtotal == 0">
                </multiselect>
                <input type="text" class="w-full border-1 border-[#e8e8e8] rounded-md p-2"
                  :placeholder="expedition_placeholder"
                  v-if="currentData.expedition != undefined && (currentData.expedition.name == 'OTHERS' || currentData.expedition.name == 'SHOPEE')"
                  v-model="currentData.expedition_info" style="color: black!important"
                  :disabled="currentData.subtotal == 0">
                <input type="text" class="w-full border-1 border-[#e8e8e8] rounded-md p-2 text-end" placeholder="Rp 0"
                  style="color: black!important" v-model="currentData.shipping_cost"
                  @input="formatCurrency('shipping_cost', currentData.shipping_cost)"
                  :disabled="currentData.expedition.name === 'SHOPEE' || currentData.subtotal == 0">
              </div>
              <div class="text-[#8F8F8F]">
                {{ rupiah(currentData.shipping_cost_total) }}
              </div>
            </div>
            <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
              <div class="">
                Deposit
              </div>
              <div class="text-[#8F8F8F]">
                <span v-if="currentData.total_deposit > 0">{{ rupiah(currentData.total_deposit)
                  }}</span>
                <span v-else>Rp. 0,-</span>
              </div>
            </div>



            <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
              <div class="">
                Subtotal
              </div>
              <div class="text-[#8F8F8F]">
                <span v-if="currentData.grand_total > 0">{{ rupiah(currentData.grand_total) }}</span>
                <span v-else>Rp. 0,-</span>
              </div>
            </div>
            <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
              <div class="">
                Grand Total
              </div>
              <div class="text-[#8F8F8F]">
                <span>{{ rupiah(currentData.total_deposit - currentData.grand_total)
                  }}</span>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-[6rem] space-x-5 flex justify-end px-10 ">
        <button type="button" @click="insert(false, currentData.id_customer.id)"
          class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]"
          v-if="currentData.username == name_admin_on_data || currentData.type == 'Super Admin' || currentData.type == 'Business Owner'">Simpan</button>
        <button type="button" @click="back()"
          class="bg-white text-xl font-redHat text-red-500 text-center py-2 px-4 rounded font-bold border-1 border-red-500 w-[240px]">
          Batal</button>
        <!-- <button type="button" @click="shareinvoice(currentData.id_customer.id)"
          class="bg-white text-xl font-redHat text-center py-2 px-4 rounded font-bold border-1 border-[#5FBAE6] w-[240px]">
          Share invoice</button> -->
        <button type="button" @click="insert(true, currentData.id_customer.id)"
          class="bg-white text-xl font-redHat text-center py-2 px-4 rounded font-bold border-1 border-[#5FBAE6] w-[240px]">
          invoice</button>
        <!-- <button type="button" @click="back()"
          class="bg-white text-xl font-redHat text-center py-2 px-4 rounded font-bold border-1 border-[#5FBAE6] w-[240px]">
          Packing List</button> -->

      </div>
    </form>
  </div>

</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from "axios";
// import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, ShowLoading, goPage, CloseLoading, base_url, AlertPopup, AlertBottom, rupiah, rupiahInput, rupiahoutput } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect'

export default {
  components: {
    NavBar,
    Multiselect
  },
  data() {
    return {
      currentData: {
        preorder: [
          {
            no_po: "",
          }
        ],
        customer: [
        ],
        product: [],
        periode: "",
        name: "",
        whatsapp: "",
        list_order: [
          {
            no: 1,
            id_product: "",
            jenis_penjualan: "LIVE_IG",
            price: 0,
            total: "",
            order_status: "",
          }
        ],
        expedition: { name: "" },
        type: '',
        subtotal: 0,
        diskon: 0,
        deposit: 0,
        grand_total: 0,
      },
      StatusPesananList: [
        { name: "PENDING" },
        { name: "KEEP" },
        { name: "SOLDOUT" },
        { name: "CHANGE" },
        { name: "BOOK" },
        { name: "OK" }
      ],
      jenisPenjualanList: [
        { name: "LIVE IG", value: "LIVE_IG" },
        { name: "STORY IG", value: "STORY_IG" },
      ],
      ekspedisiList: [
        {
          name: "LION BIG PACK",
        },
        {
          name: "LION BOSS PACK",
        },
        {
          name: "LION JAGO PACK",
        },
        {
          name: "LION REG PACK",
        },
        {
          name: "SHOPEE",
        },
        {
          name: "PICKUP",
        },
        {
          name: "OTHERS",
        }
      ],

      currentDataColorList: [],
      currentDataSizeList: [],
      currentProductList: [],

      // for alamat
      provinsiList: [],
      kabupatenList: [],
      kecamatanList: [],
      kelurahanList: [],

      expedition_placeholder: 'nama ekspedisi',
      name_admin_on_data: "",
    }
  },

  watch: {

    'currentData.id_customer'(newVal) {
      this.currentData.whatsapp = newVal.whatsapp;
      this.currentData.name = newVal.name;
      // if (newVal != null && newVal.address?.[1]) {
      //   this.currentData.address = newVal.address[1].address;
      //   this.currentData.province = newVal.address[1].province;
      //   this.currentData.city = newVal.address[1].city;
      //   this.currentData.district = newVal.address[1].district;
      //   this.currentData.sub_district = newVal.address[1].sub_district;
      //   this.currentData.pos_code = newVal.address[1].pos_code;
      // } else {
      //   this.currentData.address = '';
      //   this.currentData.province = '';
      //   this.currentData.city = '';
      //   this.currentData.district = '';
      //   this.currentData.sub_district = '';
      //   this.currentData.pos_code = '';
      // }



    },
    'currentData.list_order': {

      handler(newValue) {
        //subtotal

        this.updateUpdateSumTotal();
      },
      deep: true
    },
    'currentData.shipping_cost': {
      handler(newValue) {
        this.updateUpdateSumTotal();
      },
    },

    "currentData.expedition.name"(newVal, oldVal) {
      if (newVal) {

        // Check if the expedition name is 'SHOPEE'
        if (newVal === "SHOPEE") {
          this.currentData.shipping_cost = '';  // Set shipping cost to 0
          this.expedition_placeholder = 'Masukan no Resi';
        }
        this.updateUpdateSumTotal();
      }
    },

  },

  created() {
    const token = Cookies.get("token");

    this.config = {
      headers: { Authorization: `Bearer ` + token },
      timeout: 100000,
    }
    this.index();

  },



  methods: {
    rupiah,
    async copyToClipboard(text) {
      try {
        // Check clipboard-write permission
        const permission = await navigator.permissions.query({ name: 'clipboard-write' });
        if (permission.state === 'granted' || permission.state === 'prompt') {
          await navigator.clipboard.writeText(text);
          AlertBottom('URL invoice berhasil disalin!');
        } else {
          AlertPopup("error", "", 'Tidak ada izin untuk menyalin ke clipboard', 1500, false);
        }
      } catch (err) {
        AlertPopup("error", "", 'Terjadi kesalahan saat menyalin URL', 1500, false);
      }

      // Clear success message after 2 seconds
      setTimeout(() => {
        this.copySuccess = "";
      }, 2000);
    },

    handleShippingCostInput() {
      // Check if the expedition is 'SHOPEE'
      if (this.currentData.expedition.name === 'SHOPEE') {
        // If the value is '0' or 'Rp 0', reset it to 'Rp 0'
        if (this.currentData.shipping_cost === '0' || this.currentData.shipping_cost === 'Rp 0') {
          this.currentData.shipping_cost = 'Rp 0';
        }
      }
    },


    generateInvoice(viewinvoice, customer_id) {
      ShowLoading();
      let formData = new FormData();
      formData.append('id_preorder', localStorage.getItem('id_preorder'))
      formData.append('id_customer', this.currentData.id_customer.id)
      formData.append('diskon', this.currentData.diskon)

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'generate_invoice';
      } else {
        this.api = base_url + 'generate_invoice';
      }
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          this.records = [];
          if (response.data.status === true) {
            this.records = response.data.data;
            this.dataCustomer = response.data.customer_deposit[0];

            this.dataTotal = response.data.total[0];
            this.invoice = response.data.no_invoice;
            this.date = response.data.date_invoice;
            // save to local storage
            // localStorage.setItem('data_supplier', JSON.stringify(response.data.data_supplier));
            // localStorage.setItem('data_product_detail', JSON.stringify(response.data.product_detail));
            // if (response.data.status == true) {
            //     this.data = [];
            //     response.data.data.forEach((item, index) => {
            //         let item_prepare = { ...item, no: (index + 1), };
            //         this.data.push(item_prepare);
            //     });
            //     CloseLoading();
            // }

            if (viewinvoice == true) {
              this.viewinvoice(customer_id);
            }
            CloseLoading();
          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });

    },
    updateUpdateSumTotal() {
      this.currentData.subtotal = this.currentData.list_order
        .filter(order => order.order_status.name == 'OK' || order.order_status.name == 'CHANGE') // Filter orders with status 'OK'
        .reduce((sum, order) => {
          return sum + rupiahoutput(order.total);
        }, 0);


      //diskon 
      let arrProdukNormalPrice = [];
      this.currentData.diskon = 0;

      const listOrderCopy = JSON.parse(JSON.stringify(this.currentData.list_order)); // Non-reactive copy

      listOrderCopy.forEach((item) => {
        if (item.id_product != null && item.id_product.type_price === 'NORMAL_PRICE' &&
          (item.order_status.name === 'OK' || item.order_status.name === 'CHANGE') && item.type.value == 'STORY_IG') {
          const index = arrProdukNormalPrice.findIndex((record) => record.id_product.id === item.id_product.id);
          if (index > -1) {
            arrProdukNormalPrice[index].qty += item.qty;
          } else {
            arrProdukNormalPrice.push(item);
          }
        }
      });

      if (arrProdukNormalPrice.length > 0) {
        //perproduk
        // arrProdukNormalPrice.forEach((item) => {
        //     const index = this.currentData.discount.findIndex((record) => {
        //         // Convert `id_product` string to an array and ensure consistency with `item.id_product.id`
        //         // const productIds = record.id_product.split(',').map(id => id.trim());

        //         // productIds.some(productId => productId === String(item.id_product.id)) &&
        //         return (record.qty <= item.qty && item.qty <= record.qty_max) &&
        //             (record.type_discount.toLowerCase() === this.currentData.id_customer.type.toLowerCase());
        //     });
        //     if (index > -1) {
        //         if (this.currentData.discount[index].type == 'TOTAL') {
        //             this.currentData.diskon += this.currentData.discount[index].amount;
        //         } else {
        //             this.currentData.diskon += this.currentData.discount[index].amount * item.qty;
        //         }
        //     }
        // });
        //per total 

        const total = arrProdukNormalPrice.reduce((sum, item) => sum + item.qty, 0);
        const index = this.currentData.discount.findIndex((record) => {
          return (record.qty <= total && total <= record.qty_max) &&
            (record.type_discount.toLowerCase() == this.currentData.id_customer.type.toLowerCase());
        });

        if (index > -1) {
          if (this.currentData.discount[index].type == 'TOTAL') {
            this.currentData.diskon += this.currentData.discount[index].amount;
          } else {
            this.currentData.diskon += this.currentData.discount[index].amount * total;
            // console.log('total' + total);
            // console.log('diskon' + this.currentData.discount[index].amount);
            // console.log(arrProdukNormalPrice);
          }
        }
      } else {
        this.currentData.diskon = 0;
      }
      // this.currentData.diskon = this.currentData.list_order.reduce((sum, order) => {
      //     return sum + rupiahoutput(order.total);
      // }, 0);

      //grand total
      if (this.currentData.subtotal - this.currentData.diskon > 0) {
        let weightGoods = this.currentData.expedition?.name === 'LION BIG PACK' && this.currentData.total_weight < 10000 ? 10 :
          Math.floor(this.currentData.total_weight / 1000) + (this.currentData.total_weight % 1000 >= 300 ? 1 : 0) ||
          (this.currentData.total_weight > 0 && this.currentData.total_weight < 1000 ? 1 : 0);

        if (this.currentData.expedition?.name === "LION BIG PACK" && weightGoods < 10000) {
          weightGoods = 10000 / 1000; // Set minimum weight to 10 kg
        }

        // Reset to actual weight for other expeditions
        if (this.currentData.expedition?.name !== "LION BIG PACK") {
          weightGoods = this.currentData.expedition?.name === 'LION BIG PACK' && this.currentData.total_weight < 10000 ? 10
            : Math.floor(this.currentData.total_weight / 1000) + (this.currentData.total_weight % 1000 >= 300 ? 1 : 0) ||
            (this.currentData.total_weight > 0 && this.currentData.total_weight < 1000 ? 1 : 0); // Use actual weight
        }


        // const total_weight = this.currentData.total_weight / 1000;
        const total_weight = weightGoods;
        const firstNumber = total_weight;
        const decimalPart = total_weight % 1;
        const firstDecimalDigit = Math.floor(decimalPart * 10);

        if (this.currentData.expedition?.name === "LION BIG PACK") {
          // No multiplication for "LION BIG PACK"
          this.currentData.shipping_cost_total = rupiahoutput(this.currentData.shipping_cost);
        } else {

          if (firstDecimalDigit == 0) {
            if (firstNumber == 0) {
              this.currentData.shipping_cost_total = rupiahoutput(this.currentData.shipping_cost) * 1;
            } else {
              this.currentData.shipping_cost_total = rupiahoutput(this.currentData.shipping_cost) * firstNumber;
            }

          } else {
            this.currentData.shipping_cost_total = rupiahoutput(this.currentData.shipping_cost) * (firstNumber + (firstDecimalDigit >= 3 ? 1 : 0)); //1
          }
        }

        this.currentData.grand_total = this.currentData.subtotal - this.currentData.diskon + this.currentData.shipping_cost_total;
      } else {
        this.currentData.shipping_cost_total = rupiahoutput(this.currentData.shipping_cost);
        this.currentData.grand_total = 0 + this.currentData.shipping_cost_total;

      }

    },


    formatCurrency(label, number) {
      this.currentData[label] = rupiahInput(number);

    },
    viewinvoice(id_customer) {
      localStorage.setItem('id_customer', id_customer);
      localStorage.setItem('diskon', this.currentData.diskon);
      //before open set the title of the html

      window.open('viewinvoice', 'viewinvoice');
    },
    shareinvoice(id_customer) {
      ShowLoading();
      let formData = new FormData();
      formData.append('id_preorder', localStorage.getItem('id_preorder'))
      formData.append('id_customer', id_customer)
      formData.append('diskon', this.currentData.diskon)
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'cek_invoice';
      } else {
        this.api = base_url + 'cek_invoice';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            this.copyToClipboard(window.location.origin + '/viewinvoice?no_invoice=' + response.data.no_invoice)
            CloseLoading();
          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });

    },
    nameWithLangProduct({ code }) {
      return `${code}`
    },
    nameWithLang({ name }) {
      return `${name}`
    },
    colorWithLang({ color }) {
      return `${color}`
    },
    sizeWithLang({ size }) {
      return `${size}`
    },
    instagramWithLang({ instagram }) {
      return `${instagram}`
    },
    allowEdit(item) {
      // if (item.order_status == null || item.order_status == '') {
      //   return false;
      // } else {
      //   if (item.status_generate_buy == 'GENERATE' || item.order_status.name != 'BOOK') {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
      return true;

    },
    beforeProductChange(index) {
      let arrProduk = [];
      if (this.currentData.list_order[index].type != null && this.currentData.list_order[index].type != undefined && this.currentData.list_order[index].type != '' && this.currentData.list_order[index].type.value == 'STORY_IG') {
        this.currentData.product.forEach((item) => {

          if (item.type_sale == 'STORY_IG') {
            arrProduk.push(item);
          }
        });
        this.currentProductList = arrProduk;
      } else {
        this.currentData.product.forEach((item) => {
          arrProduk.push(item);
        });
        this.currentProductList = arrProduk;
      }
    },
    afterProductChange(index) {
      if (this.currentData.list_order[index].id_product != null && this.currentData.list_order[index].id_product != undefined) {
        this.currentData.list_order[index].normal_price = rupiahInput(this.currentData.list_order[index].id_product.normal_price);
        this.currentData.list_order[index].total = rupiahInput(this.currentData.list_order[index].id_product.normal_price * this.currentData.list_order[index].qty);
        const index2 = this.currentData.product.findIndex((record) => record.id === this.currentData.list_order[index].id_product.id);
        this.currentDataColorList = this.currentData.product[index2].product_detail;
      }
    },
    openColorChange(index) {
      if (this.currentData.list_order[index].id_product != null && this.currentData.list_order[index].id_product != undefined && this.currentData.list_order[index].id_product != '') {
        this.currentData.list_order[index].normal_price = rupiahInput(this.currentData.list_order[index].id_product.normal_price);
        this.currentData.list_order[index].total = rupiahInput(this.currentData.list_order[index].id_product.normal_price * this.currentData.list_order[index].qty);
        const index2 = this.currentData.product.findIndex((record) => record.id === this.currentData.list_order[index].id_product.id);
        this.currentDataColorList = this.currentData.product[index2].product_detail;
      }
    },
    handleColorChange(index) {
      // this.currentData.list_order[index].color;
      // this.currentData.list_order[index].id_product.id;
      if (this.currentData.list_order[index].color) {
        const indexProduct = this.currentData.product.findIndex((record) => record.id === this.currentData.list_order[index].id_product.id);
        const indexColor = this.currentData.product[indexProduct].product_detail.findIndex((record) => record.color === this.currentData.list_order[index].color.color);
        this.currentDataSizeList = this.currentData.product[indexProduct].product_detail[indexColor].sizeqty;
      }

    },
    formatDateRange(start_date, end_date) {
      const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

      // Convert strings to date objects
      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);

      // Extract day, month, and year
      const startDay = startDateObj.getDate();
      const endDay = endDateObj.getDate();
      const startMonth = monthNames[startDateObj.getMonth()];
      const endMonth = monthNames[endDateObj.getMonth()];
      const startYear = startDateObj.getFullYear();
      const endYear = endDateObj.getFullYear();

      // Check if months and years are the same
      if (startMonth === endMonth && startYear === endYear) {
        // If same month and year, return single range format
        return `${startDay}-${endDay} ${startMonth} ${startYear}`;
      } else if (startYear === endYear) {
        // If same year but different months
        return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
      } else {
        // If different years (edge case)
        return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
      }
    },
    focusNext(event, index) {
      event.preventDefault();
      const inputs = Array.from(document.querySelectorAll('#focusNext')); // Get all input elements
      const currentIndex = inputs.indexOf(event.target); // Find the current input's index

      if (currentIndex !== -1 && inputs[currentIndex + 1]) {
        // Focus on the next input if it exists
        inputs[currentIndex + 1].focus();
      } else if (index === this.currentData.list_order.length - 1) {
        // Add a new row if the Enter key is pressed on the last input of the last row
        // this.addNewRow();
        // CloseLoading();
      }
    },
    addNewRow() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(currentDate.getDate()).padStart(2, '0');
      this.currentData.list_order.push({
        id: 0,
        id_product: "",
        type: { name: "STORY IG", value: "STORY_IG" },
        qty: "",
        total: 0,
        normal_price: rupiahInput(0),
        total: "",
        order_status: { name: "BOOK" },
        date: `${year}-${month}-${day}`,
      })

    },
    deleterow(index) {
      // console.log('tes button delete')
      this.currentData.list_order.splice(index, 1);
    },
    addNewCustomer() {
      // for batal edit customer page
      localStorage.setItem('lastPage', 'current_page'); // Replace 'current_page' with the current page identifier

      // Navigate to the addcustomer page
      goPage('addcustomer');

      // localStorage.setItem('currentData', JSON.stringify(item));
    },
    index() {
      ShowLoading();
      let formData = new FormData();
      formData.append('id_preorder', localStorage.getItem('id_preorder'))
      formData.append('id_customer', localStorage.getItem('id_customer'))

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'report_daily_get';
      } else {
        this.api = base_url + 'report_daily_get';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          // console.log(response.data)
          if (response.data.status === true) {

            this.currentData = response.data.data[0];
            //address 


            this.currentData.discount = response.data.discount;

            this.currentData.product = response.data.product;
            this.currentData.preorder = response.data.preorder;
            this.currentData.customer = response.data.customer;
            this.currentData.username = response.data.username;
            this.currentData.type = response.data.type;

            this.currentData.periode = this.formatDateRange(this.currentData.preorder[0].start_date, this.currentData.preorder[0].end_date);

            //set customer 
            const index = this.currentData.customer.findIndex((record) => record.id === this.currentData.id_customer);

            this.currentData.id_customer = this.currentData.customer[index];
            let arrList = [];


            this.currentData.list_order.forEach((item) => {
              //index product
              const index = this.currentData.product.findIndex((record) => record.id === item.id_product);
              //index Color 
              const indexColor = this.currentData.product[index].product_detail.findIndex((record) => record.color.toLowerCase() == item.color.toLowerCase());
              //index Size 
              const indexSize = this.currentData.product[index].product_detail[indexColor].sizeqty.findIndex((record) => record.size.toLowerCase() == item.size.toLowerCase());

              const indexOrderStatus = this.StatusPesananList.findIndex((record) => record.name === item.order_status);

              const indexJenisPenjualan = this.jenisPenjualanList.findIndex((record) => record.value == item.type);

              const item_prepare = {
                ...item,
                id_product: this.currentData.product[index],
                normal_price: rupiahInput(item.normal_price),
                total: rupiahInput(item.total),
                order_status: this.StatusPesananList[indexOrderStatus],
                color: this.currentData.product[index].product_detail[indexColor],
                size: this.currentData.product[index].product_detail[indexColor].sizeqty[indexSize],
                type: this.jenisPenjualanList[indexJenisPenjualan],
              }
              this.currentData.jenis_penjualan;

              arrList.push(item_prepare);
            });

            this.currentData.list_order = arrList;
            this.currentData.total_deposit = this.currentData.total_deposit;
            this.currentData.nama_admin = this.currentData.nama_admin;
            this.name_admin_on_data = this.currentData.nama_admin;

            //set ongkos kirim
            const index_expedition = this.ekspedisiList.findIndex((record) => record.name === this.currentData.expedition);

            this.currentData.shipping_cost = rupiahInput(this.currentData.shipping_cost);
            if (index_expedition > -1) {
              this.currentData.expedition = this.ekspedisiList[index_expedition];
            } else {
              this.currentData.expedition_info = this.currentData.expedition;
              this.currentData.expedition = { name: 'OTHERS' };
            }
            if (this.currentData.customer_detail.address.length > 0 && this.currentData.customer_detail.address[0] != undefined) {
              const index = this.currentData.customer_detail.address.findIndex((record) => record.address_status == 'MAIN');
              if (index > -1) {
                this.currentData.address = this.currentData.customer_detail.address[index].address;
                this.currentData.province = this.currentData.customer_detail.address[index].province;
                this.currentData.city = this.currentData.customer_detail.address[index].city;
                this.currentData.district = this.currentData.customer_detail.address[index].district;
                this.currentData.sub_district = this.currentData.customer_detail.address[index].sub_district;
              } else {
                this.currentData.address = '';
                this.currentData.province = '';
                this.currentData.city = '';
                this.currentData.district = '';
                this.currentData.sub_district = '';
              }
            }
            CloseLoading();


          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }

        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });
    },
    insert(viewinvoice, customer_id) {

      if (viewinvoice && this.currentData.subtotal == 0) {
        AlertPopup("error", "", 'Terdapat Barang yang soldout / Sub total tidak boleh 0', 2000, false);
        return true;
      }
      if (this.currentData.address == '') {
        AlertPopup("error", "", 'Alamat tidak boleh kosong untuk customer, silahkan tambahkan alamat customer terlebih dahulu di menu customer', 2000, false);
        return true;
      }


      ShowLoading();
      let formData = new FormData();
      formData.append('id_preorder', localStorage.getItem('id_preorder'))
      formData.append('id_customer', localStorage.getItem('id_customer'))
      formData.append('shipping_cost', rupiahoutput(this.currentData.shipping_cost))
      formData.append('shipping_cost_total', rupiahoutput(this.currentData.shipping_cost_total))
      if (this.currentData.expedition != null && this.currentData.expedition != undefined && this.currentData.expedition != '' && this.currentData.expedition.name != 'SHOPEE' && this.currentData.expedition.name != 'OTHERS') {
        formData.append('expedition', this.currentData.expedition.name)
      } else {
        formData.append('expedition', this.currentData.expedition.name)
        formData.append('expedition_info', this.currentData.expedition_info)
      }

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'report_daily_update';
      } else {
        this.api = base_url + 'report_daily_update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status === true) {
            AlertBottom(response.data.message);
            CloseLoading();
            this.generateInvoice(viewinvoice, customer_id);


          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });

    },
    checkAdmin() {
      ShowLoading();
      let formData = new FormData();
      formData.append('id_preorder', localStorage.getItem('id_preorder'))
      formData.append('type', 'STORY_IG')

      let prepare_data = [];
      this.currentData.list_order.forEach((item) => {
        const prepare_item = { ...item, id_customer: this.currentData.id_customer.id, id_product: item.id_product.id, color: item.color.color, size: item.size.size, order_status: item.order_status.name, type: item.type.value, delete: false }
        prepare_data.push(prepare_item);
      });

      formData.append('data', JSON.stringify(prepare_data));


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'list_order_update';
      } else {
        this.api = base_url + 'list_order_update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status === true) {
            AlertBottom(response.data.message);
            CloseLoading();
          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });

    },
    clearForm() {
      this.currentData = {
        instagram: null,
        customer_detail: [],
        product_detail: [],
        address: []
        // Add other fields if necessary
      };
      this.selectedButton = null;
      this.date = '';
      this.type_rekap = '';
    },
    back() {
      window.close();
      // goPage('dailyreport')
    },
  },
}
</script>

<style scoped>
input[type='text'],
input[type='date'],
input[type='qty'],
td {
  color: #AAABAD;
}

input:disabled::placeholder {
  color: grey;
  /* Change to the grey color you want */
}


.name_product {
  color: black !important;
  font-weight: bold !important;

}

.name_product::after {
  content: '*';
  position: absolute;
  top: 0;
  left: 0;
}
</style>