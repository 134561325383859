<template>
    <NavBar />
    <header class="lg:px-16 px-5 bg-white flex flex-wrap items-center py-3">
        <div class="flex-1 flex justify-between items-center pt-3">
            <a href="#" class="text-2xl font-redHat text-[#5FBAE6] font-bold">PO Story Instagram</a>
        </div>
    </header>
    <div class="px-5 py-4">
        <form @submit.prevent>
            <div class="card rounded-[10px] border-1 border-[#5FBAE6]/30 p-4 h-full bg-[#FAF8F8]">
                <div class="grid grid-cols-12 ">
                    <div class="col-span-4 me-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">PO Batch <span
                                class="text-red-600">*</span></label>
                        <input type="text" v-model="currentData.preorder[0].no_po"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" disabled>
                    </div>
                    <div class="col-span-4 me-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">Periode <span
                                class="text-red-600">*</span></label>
                        <input type="text" v-model="currentData.periode"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" disabled>
                    </div>
                    <div class="col-span-4 me-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">Admin <span
                                class="text-red-600">*</span></label>
                        <!-- <input type="text" v-model="currentData.nama_admin"
                                class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" disabled> -->



                        <multiselect v-model="currentData.id_user" :options="currentData.users"
                            :custom-label="adminWithLang" placeholder="" label="name" track-by="" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width" :disabled="!editCustomer"></multiselect>



                    </div>
                    <div class="col-span-4 me-3 mt-3">
                        <div class="flex items-center justify-between">
                            <label for="" class="block font-redHat text-gray-400 mb-2 me-2">Instagram <span
                                    class="text-red-600">*</span></label>
                            <!-- <button type="button" @click="addNewCustomer" class="text-[#5FBAE6] text-[22px]"><i
                                    class="bi bi-plus-circle-fill"></i></button>  -->
                        </div>
                        <multiselect v-model="currentData.id_customer" :options="currentData.customer"
                            :custom-label="instagramWithLang" placeholder="" label="name" track-by=""
                            @open="getCustomer" :selectLabel="''" :deselectLabel="''" class="multiselect-full-width"
                            :disabled="!editCustomer"></multiselect>
                    </div>
                    <div class="col-span-4 me-3 mt-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">Nama Customer <span
                                class="text-red-600">*</span></label>
                        <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                            v-model="currentData.name" disabled>
                    </div>
                    <div class="col-span-4 me-3 mt-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">No. Whatsapp <span
                                class="text-red-600">*</span></label>
                        <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder=""
                            v-model="currentData.whatsapp" disabled>
                    </div>
                    <!-- alamat Pengiriman-->
                    <div class="col-span-12 mt-3">
                        <label for="" class="block font-redHat text-gray-400 mb-2">Alamat Pengiriman<span
                                class="text-red-600">*</span></label>
                        <div class="flex gap-3">
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="Alamat Pengiriman" v-model="currentData.address" disabled>
                            <!-- <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="Provinsi" v-model="currentData.province" disabled>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="Kota/Kabupaten" v-model="currentData.city" disabled>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="Kecamatan" v-model="currentData.district" disabled>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="Kelurahan" v-model="currentData.sub_district" disabled>
                            <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                                placeholder="kode pos" v-model="currentData.pos_code" disabled> -->
                        </div>
                    </div>
                    <!-- List Orderan -->
                    <!-- <p>{{ currentData.usertype }}</p> -->
                    <div class="col-span-12 mt-4">
                        <div class="flex justify-between items-center ">
                            <label for="" class="block font-redHat text-black ">List Orderan</label>
                        </div>
                        <table class="text-sm text-left rtl:text-right text-[#8F8F8F] mb-4 ">
                            <thead class="text-xs text-gray-700 bg-[#F1F1F1] shadow-sm shadow-[#8F8F8F] mb-5">
                                <tr>
                                    <th scope="col"
                                        class="px-6 py-3 text-[#8F8F8F] font-redHat rounded-l-sm   text-center">
                                        No.
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat text-center">
                                        Tanggal
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat    text-center">
                                        Kode Produk
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat    text-center">
                                        Varian
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat    text-center">
                                        Keterangan
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat    text-center">
                                        Size
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat   text-center">
                                        Jenis Penjualan
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat text-center">
                                        Qty
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat text-center  ">
                                        Order Status
                                    </th>
                                    <th scope="col" class="px-6 py-3 text-[#8F8F8F] font-redHat text-center  ">
                                        Harga Satuan
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-[#8F8F8F] font-redHat rounded-r-sm text-center">
                                        Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="bg-white border-1 border-spacing-1 " v-for="(item, index) in filteredOrder"
                                    :key="index" v-show="currentData.id_customer != null">
                                    <td scope="row"
                                        class="px-2 py-2 font-medium text-gray-900 font-redHat  whitespace-nowrap text-center">
                                        <!-- <span class="tooltip2" :style="{ '--tooltip-content': `'${item.info}'` }"
                                            v-if="item.info != null">
                                            <i class="bi bi-info text-blue-600"></i>
                                        </span> -->
                                        {{ index + 1 }}
                                        <button type="button" v-if="filteredOrder.length > 1" @click="deleterow(index)"
                                            class="cursor-pointer" :disabled="allowEditDelete(item)"
                                            :class="{ 'opacity-50': allowEditDelete(item) }"><i
                                                class="bi bi-trash text-red-400"></i></button>
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <input type="date" class="w-full h-[30px]" id="listOrderTable"
                                            v-model="item.date" placeholder="" :disabled="allowEdit(item)"
                                            :min="currentDate" :max="currentDate">
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1 ">

                                        <multiselect v-model="item.id_product" :options="currentProductList"
                                            :custom-label="nameWithLangProduct" placeholder="Pilih product" label="name"
                                            :track-by="'id'" :selectLabel="''" :deselectLabel="''"
                                            :class="{ 'name_product': item.type_price == 'NET_PRICE' }"
                                            @open="getProduct(index);" @update:modelValue="afterProductChange(index)"
                                            open-direction="bottom" :disabled="allowEdit(item)"> </multiselect>
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <multiselect v-model="item.color" :options="currentDataColorList"
                                            :custom-label="colorWithLang" placeholder="Pilih product" label="name"
                                            :track-by="'color'" :selectLabel="''" :deselectLabel="''" class=""
                                            @open="openColorChange(index)" @update:modelValue="handleColorChange(index)"
                                            open-direction="bottom" :disabled="allowEditVarian(item)">
                                        </multiselect>
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <input type="text" class="w-full h-[30px]" id="listOrderTable"
                                            v-model="item.info" placeholder="" :disabled="allowEditVarian(item)">
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <multiselect v-model="item.size" :options="currentDataSizeList"
                                            :custom-label="sizeWithLang" placeholder="Pilih product" label="name"
                                            :track-by="'size'" :selectLabel="''" :deselectLabel="''" class=""
                                            @open="handleColorChange(index)" open-direction="bottom"
                                            :disabled="allowEditVarian(item)"> </multiselect>
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <multiselect v-model="item.type" :options="jenisPenjualanList"
                                            :custom-label="nameWithLang" placeholder="Pilih jenis" label="name"
                                            :track-by="'name'" :selectLabel="''" :deselectLabel="''" class=""
                                            @open="handleColorChange(index)" open-direction="bottom" disabled>
                                        </multiselect>

                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1 ">
                                        <input type="number" class="w-full h-[30px] text-center" id="listOrderTable"
                                            v-model="item.qty" placeholder="0" min="1"
                                            @input="afterProductChange(index)"
                                            @keydown.enter="focusNext($event, index);" :disabled="allowEdit(item)">
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1">
                                        <multiselect v-model="item.order_status" :options="StatusPesananListFilter"
                                            placeholder="pilih" label="name" :custom-label="nameWithLang"
                                            :selectLabel="''" :deselectLabel="''" class="multiselect-full-width"
                                            open-direction="bottom" @open="beforeOrderStatusChange(index)"
                                            :disabled="allowEditOrderStatus(item)"
                                            @update:modelValue="handleOrderStatusChange(index)">
                                        </multiselect>
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1 text-center">
                                        <span>
                                            {{ item.normal_price }}
                                        </span>
                                    </td>
                                    <td class="px-2 py-2 text-gray-900 font-redHat border-1 text-center">
                                        <span>
                                            {{ item.total }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Total
                            </div>
                            <div class="text-[#8F8F8F]">
                                <span v-if="currentData.subtotal > 0">{{ rupiah(currentData.subtotal) }}</span>
                                <span v-else>Rp. 0,-</span>

                            </div>
                        </div>
                        <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Diskon
                            </div>
                            <div class="text-[#8F8F8F]">
                                <span v-if="currentData.diskon > 0">{{ rupiah(currentData.diskon) }}</span>
                                <span v-else>Rp. 0,-</span>
                            </div>
                        </div>
                        <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Total Barang
                            </div>
                            <div class="text-[#8F8F8F]">
                                {{ currentData.total_qty }}
                            </div>
                        </div>
                        <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Total Berat
                            </div>
                            <div class="text-[#8F8F8F]">
                                <span>{{ currentData.total_weight / 1000 + ' kg -> ' }}</span>
                                <span v-if="currentData.total_weight > 0">
                                    {{ currentData.expedition === 'LION BIG PACK' && currentData.total_weight < 10000 ?
                                        10 : Math.floor(currentData.total_weight / 1000) + (currentData.total_weight %
                                            1000 >= 300 ? 1 : 0) ||
                                        (currentData.total_weight > 0 && currentData.total_weight < 1000 ? 1 : 0) }}
                                            kg</span>
                            </div>
                        </div>
                        <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Total Ongkos Kirim
                            </div>
                            <div class="text-[#8F8F8F]">
                                <span v-if="currentData.subtotal > 0">{{ rupiah(currentData.shipping_cost_total)
                                    }}</span>
                                <span v-else>Rp. 0,-</span>
                            </div>
                        </div>
                        <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Deposit
                            </div>
                            <div class="text-[#8F8F8F]">
                                <span v-if="currentData.total_deposit > 0">{{ rupiah(currentData.total_deposit)
                                    }}</span>
                                <span v-else>Rp. 0,-</span>
                            </div>
                        </div>
                        <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Subtotal
                            </div>
                            <div class="text-[#8F8F8F]">
                                <span v-if="currentData.subtotal > 0">{{ rupiah(currentData.subtotal -
                                    currentData.diskon +
                                    currentData.shipping_cost_total) }}</span>
                                <span v-else>Rp. 0,-</span>
                            </div>
                        </div>
                        <div class="w-full bg-white flex items-center justify-between h-[55px] p-3 mb-2 rounded-md">
                            <div class="">
                                Grand Total
                            </div>
                            <div class="text-[#8F8F8F]">
                                <span v-if="currentData.subtotal > 0">{{ rupiah(currentData.total_deposit -
                                    (currentData.subtotal - currentData.diskon +
                                        currentData.shipping_cost_total)) }}</span>
                                <span v-else>Rp. 0,-</span>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="mb-3 mt-[6rem] space-x-5 flex justify-end px-10 ">
                <button type="button" @click="insert"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]"
                    v-if="(currentData.username == name_admin_on_data || editCustomer == true)">Simpan
                </button>
                <button type="button" @click="back()"
                    class="bg-white text-xl font-redHat text-red-500 text-center py-2 px-4 rounded font-bold border-1 border-red-500 w-[240px]">
                    Batal</button>

            </div>
        </form>
    </div>

</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from "axios";
// import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, ShowLoading, goPage, CloseLoading, base_url, AlertPopup, AlertBottom, rupiah, rupiahInput, rupiahoutput } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect'

export default {
    components: {
        NavBar,
        Multiselect
    },
    data() {
        return {
            currentData: {
                preorder: [
                    {
                        no_po: "",
                    }
                ],
                customer: [
                ],
                product: [],
                periode: "",
                name: "",
                whatsapp: "",
                list_order: [
                    {
                        no: 1,
                        id_product: "",
                        jenis_penjualan: "LIVE_IG",
                        price: 0,
                        total: "",
                        order_status: "",
                    }
                ],
                users: [],
                usertype: '',

                subtotal: 0,
                diskon: 0,
                deposit: 0,
                grand_total: 0,
            },
            StatusPesananList: [
                { name: "PENDING" },
                { name: "CHANGE" },
                { name: "KEEP" },
                { name: "SOLDOUT" },
                { name: "BOOK" },
                { name: "OK" }
            ],
            StatusPesananListFilter: [],
            jenisPenjualanList: [
                { name: "LIVE IG", value: "LIVE_IG" },
                { name: "STORY IG", value: "STORY_IG" },
            ],

            currentDataColorList: [],
            currentDataSizeList: [],
            currentProductList: [],

            // for alamat
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],


            name_admin_on_data: "",
            editCustomer: false,
            currentDate: this.formatDate(new Date()),

            status_load_customer: false,
            status_load_product: false,
        }
    },

    computed: {
        filteredOrder() {
            // console.log(
            //     this.currentData.list_order.filter(item => item.delete == false)
            // );

            return this.currentData.list_order.filter(item => item.delete == false);

        }
    },

    watch: {

        'currentData.id_customer'(newVal) {

            if (newVal != null && newVal.address?.[0]) {
                const mainAddress = newVal.address.find(record => record.address_status == 'MAIN') || {};
                this.currentData.address = mainAddress.address || '';
                this.currentData.province = mainAddress.province || '';
                this.currentData.city = mainAddress.city || '';
                this.currentData.district = mainAddress.district || '';
                this.currentData.sub_district = mainAddress.sub_district || '';
                this.currentData.pos_code = mainAddress.pos_code || '';
                this.currentData.whatsapp = newVal.whatsapp || '';
                this.currentData.name = newVal.name || '';
            } else {
                this.currentData.address = '';
                this.currentData.province = '';
                this.currentData.city = '';
                this.currentData.district = '';
                this.currentData.sub_district = '';
                this.currentData.pos_code = '';
                this.currentData.whatsapp = '';
                this.currentData.name = '';
            }
        },
        'currentData.list_order': {
            handler(newValue) {
                //subtotal
                this.currentData.subtotal = this.filteredOrder.reduce((sum, order) => {
                    return sum + rupiahoutput(order.total);
                }, 0);

                //diskon 
                let arrProdukNormalPrice = [];
                this.currentData.diskon = 0;

                const listOrderCopy = JSON.parse(JSON.stringify(this.currentData.list_order)); // Non-reactive copy

                listOrderCopy.forEach((item) => {
                    if (item.id_product != null && item.id_product.type_price === 'NORMAL_PRICE' &&
                        (item.order_status.name === 'OK' || item.order_status.name === 'CHANGE') && item.type.value == 'STORY_IG') {
                        const index = arrProdukNormalPrice.findIndex((record) => record.id_product.id === item.id_product.id);

                        if (index > -1) {
                            arrProdukNormalPrice[index].qty += item.qty;
                        } else {
                            arrProdukNormalPrice.push(item);
                        }
                    }
                });
                if (arrProdukNormalPrice.length > 0) {
                    //perproduk
                    // arrProdukNormalPrice.forEach((item) => {
                    //     const index = this.currentData.discount.findIndex((record) => {
                    //         // Convert `id_product` string to an array and ensure consistency with `item.id_product.id`
                    //         // const productIds = record.id_product.split(',').map(id => id.trim());

                    //         // productIds.some(productId => productId === String(item.id_product.id)) &&
                    //         return (record.qty <= item.qty && item.qty <= record.qty_max) &&
                    //             (record.type_discount.toLowerCase() === this.currentData.id_customer.type.toLowerCase());
                    //     });

                    //     if (index > -1) {
                    //         if (this.currentData.discount[index].type == 'TOTAL') {
                    //             this.currentData.diskon += this.currentData.discount[index].amount;
                    //         } else {
                    //             this.currentData.diskon += this.currentData.discount[index].amount * item.qty;
                    //         }
                    //     }
                    // });

                    //per total 
                    const total = arrProdukNormalPrice.reduce((sum, item) => sum + item.qty, 0);
                    const index = this.currentData.discount.findIndex((record) => {
                        return (record.qty <= total && total <= record.qty_max) &&
                            (record.type_discount.toLowerCase() == this.currentData.id_customer.type.toLowerCase());
                    });
                    if (index > -1) {
                        if (this.currentData.discount[index].type == 'TOTAL') {
                            this.currentData.diskon += this.currentData.discount[index].amount;
                        } else {
                            this.currentData.diskon += this.currentData.discount[index].amount * total;
                        }
                    }

                } else {
                    this.currentData.diskon = 0;
                }
                // this.currentData.diskon = this.currentData.list_order.reduce((sum, order) => {
                //     return sum + rupiahoutput(order.total);
                // }, 0);

                //grand total
                if (this.currentData.subtotal - this.currentData.diskon > 0) {
                    this.currentData.grand_total = this.currentData.subtotal - this.currentData.diskon;
                } else {
                    this.currentData.grand_total = 0;
                }


            },
            deep: true
        },
    },

    created() {
        const token = Cookies.get("token");

        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }
        this.index();

    },



    methods: {
        rupiah,
        nameWithLangProduct({ code }) {
            return `${code}`
        },
        nameWithLang({ name }) {
            return `${name}`
        },
        colorWithLang({ color }) {
            return `${color}`
        },
        sizeWithLang({ size }) {
            return `${size}`
        },
        instagramWithLang({ instagram }) {
            return `${instagram}`
        },

        adminWithLang({ nama_lengkap }) {
            return `${nama_lengkap}`
        },

        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },

        allowEditDelete(item) {
            if (this.currentData.usertype == 'Business Owner') {

                if (item.order_status == null || item.order_status == '') {
                    return false;
                } else {
                    if ((item.status_generate_buy == 'GENERATE') || item.order_status.name != 'BOOK') {
                        return true;
                    } else {
                        if (item.status_generate_buy == 'SOLDOUT') {
                            return true;
                        }
                        return false;
                    }
                }

            } else {
                return true;
            }

        },
        allowEdit(item) {

            if (item.order_status == null || item.order_status == '') {
                return false;
            } else {
                if ((item.status_generate_buy == 'GENERATE') || item.order_status.name != 'BOOK') {
                    return true;
                } else {
                    return false;
                }
            }


        },

        allowEditVarian(item) {
            if (item.order_status == null || item.order_status == '' || item.change == true) {
                return false;
            } else {
                if ((item.status_generate_buy == 'GENERATE' && item.order_status.name != 'SOLDOUT') || (item.order_status.name != 'BOOK' && item.order_status.name != 'SOLDOUT')) {
                    return true;
                } else {
                    if (item.order_status.name == 'SOLDOUT') {
                        return true;
                    }
                    return false;
                }
            }
        },
        allowEditOrderStatus(item) {
            if (item.order_status == null || item.order_status == '' || item.change == true) {
                return false;
            } else {
                // if ((item.status_generate_buy == 'GENERATE' && item.order_status.name != 'SOLDOUT') || (item.order_status.name != 'SOLDOUT')) {
                //     return true;
                // } else {
                //     if ((item.order_status.name == 'SOLDOUT')) {
                //         return true;
                //     }
                //     return false;
                // }
                return item.order_status.name !== 'SOLDOUT' || item.status_generate_buy === 'GENERATE';

            }
        },
        beforeProductChange(index) {
            let arrProduk = [];
            if (this.filteredOrder[index].type != null && this.filteredOrder[index].type != undefined && this.filteredOrder[index].type != '' && this.filteredOrder[index].type.value == 'STORY_IG') {
                this.currentData.product.forEach((item) => {

                    if (item.type_sale == 'STORY_IG') {
                        arrProduk.push(item);
                    }
                });
                this.currentProductList = arrProduk;
            } else {
                this.currentData.product.forEach((item) => {
                    arrProduk.push(item);
                });
                this.currentProductList = arrProduk;
            }
        },
        afterProductChange(index) {
            if (this.filteredOrder[index].id_product != null && this.filteredOrder[index].id_product != undefined) {
                this.filteredOrder[index].normal_price = rupiahInput(this.filteredOrder[index].id_product.normal_price);
                this.filteredOrder[index].total = rupiahInput(this.filteredOrder[index].id_product.normal_price * this.filteredOrder[index].qty);
                const index2 = this.currentData.product.findIndex((record) => record.id === this.filteredOrder[index].id_product.id);
                this.currentDataColorList = this.currentData.product[index2].product_detail;
            }
        },
        beforeOrderStatusChange(index) {
            if (this.filteredOrder[index].order_status.name == 'SOLDOUT' || this.filteredOrder[index].order_status.name == 'CHANGE') {
                this.StatusPesananListFilter = [{ name: "SOLDOUT" },
                { name: "CHANGE" },];
            } else {
                this.StatusPesananListFilter = this.StatusPesananList;
            }
        },
        handleOrderStatusChange(index) {
            if (this.filteredOrder[index].order_status.name == 'CHANGE') {
                this.filteredOrder[index].change = true;
            }
        },
        openColorChange(index) {
            if (this.filteredOrder[index].id_product != null && this.filteredOrder[index].id_product != undefined && this.filteredOrder[index].id_product != '') {
                this.filteredOrder[index].normal_price = rupiahInput(this.filteredOrder[index].id_product.normal_price);
                this.filteredOrder[index].total = rupiahInput(this.filteredOrder[index].id_product.normal_price * this.filteredOrder[index].qty);
                const index2 = this.currentData.product.findIndex((record) => record.id === this.filteredOrder[index].id_product.id);
                this.currentDataColorList = this.currentData.product[index2].product_detail;
            }
        },
        handleColorChange(index) {
            if (this.filteredOrder[index].color) {
                const indexProduct = this.currentData.product.findIndex((record) => record.id === this.filteredOrder[index].id_product.id);
                const indexColor = this.currentData.product[indexProduct].product_detail.findIndex((record) => record.color === this.filteredOrder[index].color.color);
                this.currentDataSizeList = this.currentData.product[indexProduct].product_detail[indexColor].sizeqty;
            }
        },
        formatDateRange(start_date, end_date) {
            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June',
                'July', 'August', 'September', 'October', 'November', 'December'
            ];

            // Convert strings to date objects
            const startDateObj = new Date(start_date);
            const endDateObj = new Date(end_date);

            // Extract day, month, and year
            const startDay = startDateObj.getDate();
            const endDay = endDateObj.getDate();
            const startMonth = monthNames[startDateObj.getMonth()];
            const endMonth = monthNames[endDateObj.getMonth()];
            const startYear = startDateObj.getFullYear();
            const endYear = endDateObj.getFullYear();

            // Check if months and years are the same
            if (startMonth === endMonth && startYear === endYear) {
                // If same month and year, return single range format
                return `${startDay}-${endDay} ${startMonth} ${startYear}`;
            } else if (startYear === endYear) {
                // If same year but different months
                return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${startYear}`;
            } else {
                // If different years (edge case)
                return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
            }
        },
        focusNext(event, index) {
            event.preventDefault();
            const inputs = Array.from(document.querySelectorAll('#focusNext')); // Get all input elements
            const currentIndex = inputs.indexOf(event.target); // Find the current input's index

            if (currentIndex !== -1 && inputs[currentIndex + 1]) {
                // Focus on the next input if it exists
                inputs[currentIndex + 1].focus();
            } else if (index === this.filteredOrder.length - 1) {
                // Add a new row if the Enter key is pressed on the last input of the last row
                this.addNewRow();
                // CloseLoading();
            }
        },
        addNewRow() {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const day = String(currentDate.getDate()).padStart(2, '0');
            this.filteredOrder.push({
                id: 0,
                id_product: "",
                type: { name: "STORY IG", value: "STORY_IG" },
                qty: "",
                total: 0,
                normal_price: rupiahInput(0),
                total: "",
                order_status: { name: "BOOK" },
                date: `${year}-${month}-${day}`,
            })

        },
        deleterow(index) {
            // this.filteredOrder.splice(index, 1);
            // this.filteredOrder[index].delete = true;

            if (confirm("Are you sure you want to delete this row?")) {
                this.filteredOrder[index].delete = true;
            }

        },
        addNewCustomer() {
            // for batal edit customer page
            localStorage.setItem('lastPage', 'current_page'); // Replace 'current_page' with the current page identifier

            // Navigate to the addcustomer page
            goPage('addcustomer');

            // localStorage.setItem('currentData', JSON.stringify(item));
        },
        index() {
            //get data
            const currentData = JSON.parse(localStorage.getItem('currentData'));


            ShowLoading();
            let formData = new FormData();
            formData.append('id_preorder', localStorage.getItem('id_preorder'))
            formData.append('type', 'STORY_IG');
            formData.append('id_customer', currentData.id_customer);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'list_order_get_update';
            } else {
                this.api = base_url + 'list_order_get_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response.data)
                    if (response.data.status === true) {
                        this.currentData = response.data;

                        this.currentData.product = response.data.product;
                        this.currentData.periode = this.formatDateRange(this.currentData.preorder[0].start_date, this.currentData.preorder[0].end_date);
                        // this.currentData.list_order = [
                        //     {
                        //         id_product: "",
                        //         type: "STORY_IG",
                        //         qty: "",
                        //         total: 0,
                        //         normal_price: rupiahInput(0),
                        //         total: "",
                        //     }
                        // ]


                        this.currentData.total_weight = currentData.total_weight;
                        this.currentData.total_qty = currentData.total_qty;
                        this.currentData.expedition = currentData.expedition;
                        this.currentData.shipping_cost_total = currentData.shipping_cost_total;
                        if (this.currentData.usertype == 'Business Owner' || this.currentData.usertype == 'Super Admin') {
                            this.editCustomer = true;
                        } else {
                            this.editCustomer = false;
                        }

                        //set customer 
                        const index = this.currentData.customer.findIndex((record) => record.id === currentData.id_customer);
                        const indexUser = this.currentData.users.findIndex((record) => record.id == this.currentData.customer[index].id_user);

                        this.currentData.id_user = this.currentData.users[indexUser];
                        this.currentData.id_customer = this.currentData.customer[index];

                        let arrList = [];


                        // console.log('listorder', this.currentData.list_order);
                        this.currentData.list_order.forEach((item) => {
                            //index product
                            const index = this.currentData.product.findIndex((record) => record.id === item.id_product);
                            // console.log('test', index)
                            //index Color 
                            const indexColor = this.currentData.product[index].product_detail.findIndex((record) => record.color.toLowerCase() == item.color.toLowerCase());
                            //index Size 
                            const indexSize = this.currentData.product[index].product_detail[indexColor].sizeqty.findIndex((record) => record.size.toLowerCase() == item.size.toLowerCase());

                            const indexOrderStatus = this.StatusPesananList.findIndex((record) => record.name === item.order_status);

                            const indexJenisPenjualan = this.jenisPenjualanList.findIndex((record) => record.value == item.type);

                            const item_prepare = {
                                ...item,
                                id_product: this.currentData.product[index],
                                normal_price: rupiahInput(item.normal_price),
                                total: rupiahInput(item.total),
                                order_status: this.StatusPesananList[indexOrderStatus],
                                color: this.currentData.product[index].product_detail[indexColor],
                                size: this.currentData.product[index].product_detail[indexColor].sizeqty[indexSize],
                                type: this.jenisPenjualanList[indexJenisPenjualan],
                                delete: false,
                            }
                            this.currentData.jenis_penjualan;

                            arrList.push(item_prepare);


                        });


                        this.currentData.list_order = arrList;


                        this.currentData.total_deposit = currentData.total_deposit;
                        this.currentData.nama_admin = currentData.nama_admin;
                        this.name_admin_on_data = currentData.nama_admin;

                        CloseLoading();

                        // this.name_admin_on_data = currentData.nama_admin;
                        // if (currentData.nama_admin == null) {
                        //     this.currentData.nama_admin = this.currentData.username;
                        //     this.checkAdmin();
                        // } else {
                        //     this.currentData.nama_admin = currentData.nama_admin;
                        // }



                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });
        },
        getCustomer() {

            if (this.status_load_customer == false) {


                ShowLoading();
                let formData = new FormData();
                formData.append('id_preorder', localStorage.getItem('id_preorder'))
                formData.append('type', 'STORY_IG');

                if (process.env.NODE_ENV === "development") {
                    this.api = base_url + 'list_order_get_customer_data';
                } else {
                    this.api = base_url + 'list_order_get_customer_data';
                }
                axios
                    .post(this.api, formData, this.config)
                    .then((response) => {
                        if (response.data.status === true) {
                            this.currentData.customer = response.data.data;
                            this.status_load_customer = true;

                            CloseLoading();

                        } else {
                            CloseLoading();
                            AlertPopup("error", "", response.data.message, 1500, false);
                        }
                    })
                    .catch((error) => {
                        CloseLoading();
                        ErrorConnectionTimeOut(error);
                    });
            }
        },
        getProduct(index) {

            if (this.status_load_product == false) {


                ShowLoading();
                let formData = new FormData();
                formData.append('id_preorder', localStorage.getItem('id_preorder'))
                formData.append('type', 'STORY_IG');

                if (process.env.NODE_ENV === "development") {
                    this.api = base_url + 'list_order_get_product_data';
                } else {
                    this.api = base_url + 'list_order_get_product_data';
                }
                axios
                    .post(this.api, formData, this.config)
                    .then((response) => {
                        if (response.data.status === true) {
                            this.currentData.product = response.data.data;
                            this.status_load_product = true;
                            this.beforeProductChange(index);

                            CloseLoading();

                        } else {
                            CloseLoading();
                            AlertPopup("error", "", response.data.message, 1500, false);
                        }
                    })
                    .catch((error) => {
                        CloseLoading();
                        ErrorConnectionTimeOut(error);
                    });
            } else {
                this.beforeProductChange(index);
            }
        },
        insert() {

            if (this.currentData.id_user == null) {
                AlertPopup("error", "", "Nama admin masih kosong", 1500, false);
                return true;
            }
            ShowLoading();
            let formData = new FormData();
            formData.append('id_preorder', localStorage.getItem('id_preorder'))
            formData.append('type', 'STORY_IG')

            let prepare_data = [];
            let zero_qty_data = [];
            let zero_product_data = [];
            this.currentData.list_order.forEach((item) => {
                const prepare_item = { ...item, id_customer: this.currentData.id_customer.id, id_user: this.currentData.id_user.id, id_product: item.id_product.id, code_product: item.id_product.code, color: item.color?.color || null, size: item.size?.size || null, order_status: item.order_status.name, type: item.type.value }

                if (item.qty == 0) {
                    zero_qty_data.push(prepare_item);
                } else if (!item.color || !item.color.color || !item.size || !item.size.size || !item.id_product.id || !item.id_product) {
                    zero_product_data.push(prepare_item)

                } else {
                    prepare_data.push(prepare_item);
                }
            });


            if (zero_qty_data.length > 0) {
                AlertPopup("error", "", "terdapat QTY yang kosong", 1500, false);
                return true;
            }

            if (zero_product_data.length > 0) {
                AlertPopup("error", "", "terdapat item product yang kosong", 1500, false);
                return true;
            }




            formData.append('data', JSON.stringify(prepare_data));

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }
            // CloseLoading()
            // return true

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'list_order_update';
            } else {
                this.api = base_url + 'list_order_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status === true) {
                        AlertBottom(response.data.message);
                        CloseLoading();
                        // window.close();
                        goPage('listorder');
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });

        },
        checkAdmin() {
            ShowLoading();
            let formData = new FormData();
            formData.append('id_preorder', localStorage.getItem('id_preorder'))
            formData.append('type', 'STORY_IG')

            let prepare_data = [];
            this.filteredOrder.forEach((item) => {
                const prepare_item = { ...item, id_customer: this.currentData.id_customer.id, id_product: item.id_product.id, color: item.color.color, size: item.size.size, order_status: item.order_status.name, type: item.type.value }
                prepare_data.push(prepare_item);
            });

            formData.append('data', JSON.stringify(prepare_data));


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'list_order_update';
            } else {
                this.api = base_url + 'list_order_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status === true) {
                        AlertBottom(response.data.message);
                        CloseLoading();
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });

        },
        clearForm() {
            this.currentData = {
                instagram: null,
                customer_detail: [],
                product_detail: [],
                address: []
                // Add other fields if necessary
            };
            this.selectedButton = null;
            this.date = '';
            this.type_rekap = '';
        },
        back() {
            // window.close();
            goPage('listorder')
        },
    },
}
</script>

<style scoped>
.name_product {
    color: black !important;
    font-weight: bold !important;

}

.name_product::after {
    content: '*';
    position: absolute;
    top: 0;
    left: 0;
}
</style>