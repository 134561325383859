<template>
    <NavBar />
    <header class="lg:px-16 px-4 bg-[#5FBAE6] flex flex-wrap items-center py-4 shadow-md">
        <div class="flex-1 flex justify-between items-center">
            <a href="javascript.void(0)" class="text-3xl font-redHat text-[#F5CA40] font-bold">Master Data Akses</a>
        </div>



        <div class="hidden md:flex md:items-center md:w-auto w-full" id="menu">
            <nav>
                <ul class="md:flex items-center justify-between text-base text-gray-700 pt-1 md:pt-0 gap-2">
                    <li>
                        <div class="relative flex items-center p-0 rounded-[20px] w-[250px] max-w-lg h-[50px]">
                            <input type="text" v-model="searchQuery" @input="filterTable"
                                class="rounded-[20px] p-2.5 px-6 w-full h-full focus:border-blue-500 focus:ring focus:ring-blue-200 focus:outline-none"
                                name="search" placeholder="Cari">

                            <button type="submit"
                                class="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 bg-white p-2 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                    stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </button>
                        </div>
                    </li>
                    <li>
                        <RouterLink
                            class="md:p-4 py-3 px-0 block flex items-center justify-center w-[258px] h-[50px] rounded-[20px] bg-[#50C996] text-black font-redHat px-6 py-2.5 font-semibold border border-[#F1EDEA]"
                            to="addadmin">Tambah Baru</RouterLink>
                    </li>

                </ul>
            </nav>
        </div>
    </header>

    <div class="px-5 pt-5 mb-2">
        <!-- <div class="flex flex-col">
            <div class="-m-1.5 overflow-x-auto">
                <div class="p-1.5 min-w-full inline-block align-middle">
                    <div class="border border-white rounded-lg divide-y divide-white ">

                        <div class="overflow-hidden">
                           
                        </div>

                    </div>
                </div>
            </div>
        </div> -->
        <table v-if="record.length" id="example" class="display">
            <thead class="" style="
              position: sticky !important;
                top: 100px !important;
              background-color: white;
              z-index: 1;
            ">
                <tr>
                    <th>No.</th>
                    <th>Menu</th>
                    <th>Lihat Semua</th>
                    <th>Lihat</th>
                    <th>Buat</th>
                    <th>Perbaharui</th>
                    <th>Hapus</th>

                    <!-- <th>Eksport</th> -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in record" :key="index" class="tr-table">
                    <td class="custom-td-left">{{ index + 1 }}</td> <!-- Sequential Number -->
                    <td>{{ checkMenu(item.menu) }}</td>
                    <td class="">
                        <input type="checkbox" name="" id="" v-model="item.read_all" @click="update(item, 'read_all')"
                            :disabled="item.menu != 'customer_soldout_report'">
                    </td>
                    <td class="">
                        <input type="checkbox" name="" id="" v-model="item.read" @click="update(item, 'read')"
                            :disabled="item.menu == 'dashboard'">
                    </td>
                    <td class="">
                        <input type="checkbox" name="" id="" v-model="item.create" @click="update(item, 'create')">
                    </td>
                    <td class="">
                        <input type="checkbox" name="" id="" v-model="item.update" @click="update(item, 'update')">
                    </td>
                    <td class="">
                        <input type="checkbox" name="" id="" v-model="item.delete" @click="update(item, 'delete')">
                    </td>
                    <!-- <td class="custom-td-right">
                        <input type="checkbox" name="" id="" v-model="item.export" @click="update(item, 'export')">
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>



</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from "axios";
// import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
import Cookies from "js-cookie";

export default {
    components: {
        NavBar,
    },

    data() {
        return {
            record: [
            ],
            // item: {},
            isModalOpen: false,
            currentDeleteIndex: null,
            config: '',
            // filter
            searchQuery: '',
            currentData: {},
            dataTable: null,
            menuList:
                [
                    {
                        name: 'auth',
                        show_name: 'Otorisasi',
                    },
                    {
                        name: 'category',
                        show_name: 'Kategori Produk',
                    },
                    {
                        name: 'customer',
                        show_name: 'Customer',
                    },
                    {
                        name: 'user',
                        show_name: 'Admin',
                    },
                    {
                        name: 'dashboard',
                        show_name: 'Dashboard',
                    },
                    {
                        name: 'discount',
                        show_name: 'Discount',
                    },
                    {
                        name: 'list_order',
                        show_name: 'List Order & Story IG',
                    },
                    {
                        name: 'location',
                        show_name: 'Lokasi',
                    },
                    {
                        name: 'preorder',
                        show_name: 'Preorder',
                    },
                    {
                        name: 'product',
                        show_name: 'Produk',
                    },
                    {
                        name: 'supplier',
                        show_name: 'Supplier',
                    },
                    {
                        name: 'live_ig',
                        show_name: 'Live IG',
                    },
                    {
                        name: 'summary_buy',
                        show_name: 'Rekap perlu dibeli',
                    },
                    {
                        name: 'generate_summary_buy',
                        show_name: 'Generate Rekap perlu dibeli',
                    },
                    {
                        name: 'sales_report',
                        show_name: 'Laporan Penjualan',
                    },
                    {
                        name: 'deposit_report',
                        show_name: 'Laporan Deposit',
                    },
                    {
                        name: 'list_order_report',
                        show_name: 'Laporan list orderan',
                    },
                    {
                        name: 'summary_bought',
                        show_name: 'Rekap sudah dibeli',
                    },
                    {
                        name: 'packaging_list_report',
                        show_name: 'Laporan Packing list',
                    },
                    {
                        name: 'report_profit_loss',
                        show_name: 'Laporan Laba Rugi',
                    },
                    {
                        name: 'product_sales_report',
                        show_name: 'Laporan Penjualan Produk',
                    },
                    {
                        name: 'operational_expense',
                        show_name: 'Beban Operasional',
                    },
                    {
                        name: 'customer_soldout_report',
                        show_name: 'Laporan Customer Soldout',
                    },
                ],

        }
    },

    mounted() {
        // Initialize DataTable after the DOM is rendered
        this.initializeDataTable();

    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 100000,
        }


        this.index();
    },


    methods: {

        initializeDataTable() {
            // Ensure DataTable is initialized correctly
            // new DataTable('#example');
            if (this.dataTable) {
                this.dataTable.destroy();  // Destroy previous DataTable instance
            }
            this.$nextTick(() => {
                this.dataTable = window.$('#example').DataTable();  // Reinitialize DataTable

                window.$('.dt-search').addClass('flex justify-end items-center');
                window.$('select').addClass('mr-3');
                window.$('.dt-paging').addClass('flex justify-end');
                window.$('.dt-search label').addClass('mr-3');
                window.$('.dt-search label').addClass('hidden');
                window.$('.dt-search input').addClass('hidden');
                window.$('.dt-info').addClass('hidden');
                window.$('.dt-length label').text('Entri Per Halaman');
                // window.$('.paginating').addClass('active:bg-blue-300');

                // Optionally: Attach an event listener to default DataTables search input to reflect in custom input
                window.$('.dataTables_filter input').on('input', (e) => {
                    this.searchQuery = e.target.value; // Update the custom search input if the default one is used
                });
            });


        },

        filterTable() {
            if (this.dataTable) {
                // Use DataTables' search() API to filter the table based on custom input
                this.dataTable.search(this.searchQuery).draw();

                // Sync the default search input with the custom input
                window.$('.dataTables_filter input').val(this.searchQuery);
            }
        },
        edit(item) {
            localStorage.setItem('currentData', JSON.stringify(item));
        },
        index() {
            ShowLoading();
            let formData = new FormData();
            this.currentData = JSON.parse(localStorage.getItem('currentData'));
            formData.append('id_user_request', this.currentData.id);
            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'auth';
            } else {
                this.api = base_url + 'auth';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status === true) {
                        this.record = response.data.data;
                        this.record.forEach((item, index) => {
                            if (item.read == 'AKTIF') {
                                this.record[index].read = true;
                            } else {
                                this.record[index].read = false;
                            }
                            if (item.read_all == 'AKTIF') {
                                this.record[index].read_all = true;
                            } else {
                                this.record[index].read_all = false;
                            }
                            if (item.create == 'AKTIF') {
                                this.record[index].create = true;
                            } else {
                                this.record[index].create = false;
                            }
                            if (item.update == 'AKTIF') {
                                this.record[index].update = true;
                            } else {
                                this.record[index].update = false;
                            }
                            if (item.delete == 'AKTIF') {
                                this.record[index].delete = true;
                            } else {
                                this.record[index].delete = false;
                            }
                            if (item.insert == 'AKTIF') {
                                this.record[index].insert = true;
                            } else {
                                this.record[index].insert = false;
                            }
                            if (item.export == 'AKTIF') {
                                this.record[index].export = true;
                            } else {
                                this.record[index].export = false;
                            }
                        });

                        this.initializeDataTable();

                        CloseLoading();
                    } else {
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });
        },
        update(item, access) {
            ShowLoading();

            let formData = new FormData();

            this.currentData = JSON.parse(localStorage.getItem('currentData'));
            formData.append('id_user_request', item.id_users);
            formData.append('menu', item.menu);
            formData.append('access', access);
            const default_status = item[access];

            if (default_status == false) {
                formData.append('status', 'AKTIF');
            } else {
                formData.append('status', 'TIDAK');
            }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'auth_update';
            } else {
                this.api = base_url + 'auth_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status === true) {

                        item[access] = !default_status;
                        CloseLoading();
                        AlertBottom(response.data.message);
                    } else {
                        item[access] = default_status;
                        CloseLoading();
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                });
        },
        checkMenu(menu) {
            const selectedMenu = this.menuList.find(item => item.name === menu);
            // console.log(selectedMenu);
            return selectedMenu ? selectedMenu.show_name : null;
        }





    },
}
</script>
